import { Outlet } from "react-router-dom";

import { NavBarMobile } from "components/NavBarMobile";
import { SideBar } from "components/SideBar";
import { TabBar } from "components/TabBar";
import useToggleClassName from "hooks/useToggleClassName";

export const MainLayout = () => {
  useToggleClassName("loggedIn");

  return (
    <div className="flex h-full overflow-hidden bg-grey-900">
      <TabBar className="hidden lg:block" />
      <NavBarMobile className="lg:hidden" />
      <Outlet />
      <SideBar className="hidden lg:block" />
    </div>
  );
};
