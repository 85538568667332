import { IconProps } from "./types";

export function ClockCrossedIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <g fill="none">
        <path
          fill="#313381"
          d="M23.333 20.983V15A1.667 1.667 0 1 0 20 15v6.667c-.003.443.171.868.483 1.183l5 5a1.667 1.667 0 0 0 2.367 0 1.667 1.667 0 0 0 0-2.367l-4.517-4.5z"
        />
        <path
          fill="#313381"
          d="M21.667 0C9.7 0 0 9.7 0 21.667c0 11.966 9.7 21.666 21.667 21.666H22A1.667 1.667 0 1 0 22 40h-.333C11.54 40 3.333 31.792 3.333 21.667c0-10.126 8.208-18.334 18.334-18.334C31.792 3.333 40 11.541 40 21.667V22a1.667 1.667 0 0 0 3.333 0v-.333A21.667 21.667 0 0 0 21.667 0z"
        />
        <path
          fill="#2961FF"
          d="M38.333 26.667c-6.443 0-11.666 5.223-11.666 11.666C26.667 44.777 31.89 50 38.333 50 44.777 50 50 44.777 50 38.333c0-6.443-5.223-11.666-11.667-11.666zm3.684 15.35a1.667 1.667 0 0 1-2.367 0l-1.317-1.334-1.316 1.334a1.667 1.667 0 0 1-2.367 0 1.667 1.667 0 0 1 0-2.367l1.333-1.317-1.333-1.316a1.667 1.667 0 0 1 0-2.367 1.667 1.667 0 0 1 2.367 0l1.316 1.333 1.317-1.333a1.667 1.667 0 0 1 2.367 0 1.667 1.667 0 0 1 0 2.367l-1.334 1.316 1.334 1.317a1.667 1.667 0 0 1 0 2.367z"
        />
      </g>
    </svg>
  );
}
