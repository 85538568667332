import clsx from "clsx";
import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";

import { Typography } from "components/Typography";

export interface ITab {
  param?: string;
  value?: string;
  label: string;
}

interface ITabsProps {
  className?: string;
  tabClassName?: string;
  tabs: ITab[];
  isChangeParams?: boolean;
  activeTab?: ITab;
  onChange?: Dispatch<SetStateAction<ITab>>;
  children?: ReactNode;
}

export const Tabs: FC<ITabsProps> = ({
  className,
  tabClassName,
  tabs,
  isChangeParams,
  activeTab = tabs[0],
  onChange,
}) => {
  const [selectedTab, setSelectedTab] = useState(activeTab);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isChangeParams) {
      const param = searchParams.get("tab");

      if (!param) {
        setSearchParams({
          tab: tabs[0].param as string,
        });
      }

      const selectedTab = tabs.find((tab) => tab.param === param) || tabs[0];

      setSelectedTab(selectedTab);

      if (onChange) onChange(selectedTab);
    }
  }, [isChangeParams, tabs, searchParams, setSearchParams, onChange]);

  const handleTabChange = (selectedTab: ITab) => {
    if (isChangeParams) {
      return setSearchParams({
        tab: selectedTab.param as string,
      });
    }

    setSelectedTab(selectedTab);

    onChange && onChange(selectedTab);
  };

  return (
    <div className={clsx("flex w-full rounded-3xl bg-grey-900 p-1 md:w-auto", className)}>
      {tabs.map((tab) => (
        <button
          key={tab.label}
          className={clsx(
            "flex-1 rounded-3xl py-2 focus-visible:outline-none md:w-tab",
            {
              "bg-blue-700 text-white": selectedTab.value === tab.value,
              "text-grey-0 hover:bg-grey-700": selectedTab.value !== tab.value,
            },
            tabClassName
          )}
          onClick={() => handleTabChange(tab)}
        >
          <Typography className="block truncate" variant="subheading-md">
            <FormattedMessage id={tab.label} />
          </Typography>
        </button>
      ))}
    </div>
  );
};
