import { IconProps } from "./types";

export function CrossIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
      <path
        d="m8.884 7 4.71-4.71A1.332 1.332 0 1 0 11.71.407L7 5.116 2.29.406A1.332 1.332 0 1 0 .407 2.29L5.116 7l-4.71 4.71a1.332 1.332 0 1 0 1.884 1.883L7 8.884l4.71 4.71a1.332 1.332 0 1 0 1.883-1.884L8.884 7z"
        fill="currentColor"
      />
    </svg>
  );
}
