import { FormattedMessage } from "react-intl";

import { Button } from "components/ui";
import { CirclesIcon } from "icons";

const JoinFutureEarnings = () => (
  <div className=" overflow-hidden bg-blue-700 py-10 lg:pb-11 lg:pt-30">
    <div className="container relative">
      <h2 className="h2 mb-6 text-center text-white lg:text-[52px]">
        <FormattedMessage id="landing.block5.title" />
      </h2>
      <p className="h6 mx-auto mb-6 text-center text-white lg:w-7/12">
        <FormattedMessage id="landing.block5.description" />
      </p>
      <Button
        className="mx-auto w-56 border-white bg-white !text-blue-700 hover:border-grey-700 hover:bg-grey-700"
        component="Link"
        to="/sign-up"
      >
        <FormattedMessage id="buttons.signUp" />
      </Button>
      <CirclesIcon className="absolute -bottom-10 -left-36 hidden w-[472px] lg:flex" />
    </div>
  </div>
);

export default JoinFutureEarnings;
