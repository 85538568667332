import { useInfiniteQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { FC, ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { fetchGetTransactions } from "api/transactionsAPI";
import ObserverDataLoader from "components/ObserverDataLoader";
import TransactionCard from "components/TransactionCard";
import { Loader } from "components/ui";
import { LoaderSizeType } from "components/ui/Loader";
import { ITab, Tabs } from "components/ui/Tabs";
import { useAuth } from "context/AuthContext";
import { TransactionStatus } from "types/transaction";

const TRANSACTION_TABS: ITab[] = [
  { label: "tabs.tab.all", value: "all" },
  {
    label: "tabs.tab.pending",
    value: TransactionStatus.PENDING,
  },
  {
    label: "tabs.tab.complete",
    value: TransactionStatus.COMPLETE,
  },
];

interface ITransactionTabsProps {
  className?: string;
  tabsClassName?: string;
  loaderClassName?: string;
  loaderSize?: LoaderSizeType;
  children?: ReactNode;
  isSmallCards?: boolean;
}

export const TransactionTabs: FC<ITransactionTabsProps> = ({
  className,
  tabsClassName,
  loaderClassName,
  loaderSize = "large",
  children,
  isSmallCards,
}) => {
  const [activeTab, setActiveTab] = useState<ITab>(TRANSACTION_TABS[0]);

  const {
    user: { userId },
  } = useAuth();

  const {
    data,
    isFetching: isLoading,
    fetchNextPage,
    isFetched,
  } = useInfiniteQuery(
    ["transactions", activeTab],
    ({ pageParam = 1 }) =>
      fetchGetTransactions({
        userId,
        startPage: pageParam,
        endPage: pageParam,
        status: activeTab.value === TRANSACTION_TABS[0].value ? undefined : activeTab.value,
      }),
    {
      getNextPageParam: (_, allPages) => allPages.length + 1,
    }
  );

  const totalPages = data?.pages[0].totalPages;

  const isEndReached = !data || totalPages === 0 || totalPages === data?.pages.length;

  const transactions = data?.pages.flatMap((page) => page.transactions) || [];

  return (
    <div className="flex flex-col">
      <Tabs
        className={clsx("self-start", className)}
        tabClassName={tabsClassName}
        tabs={TRANSACTION_TABS}
        activeTab={activeTab}
        onChange={setActiveTab}
      />

      <div className="flex flex-col">
        {transactions.map((transaction, i) => (
          <TransactionCard
            key={transaction.paymentId + i}
            className="mb-2 rounded-2xl bg-grey-900 last:mb-0"
            transaction={transaction}
            isSmallSize={isSmallCards}
          />
        ))}
      </div>

      <div
        className={clsx(
          "mb-4 flex h-15 items-center",
          {
            "!mt-0": !transactions.length && !isLoading,
          },
          loaderClassName
        )}
      >
        {isLoading && <Loader className="mx-auto" size={loaderSize} />}

        {!transactions.length && !isLoading && (
          <p className="my-6 text-center text-h6 font-normal text-steelGrey">
            <FormattedMessage id="pages.transactions.empty" />
          </p>
        )}
      </div>

      {!isLoading && !isEndReached && (
        <ObserverDataLoader onShow={fetchNextPage} rootMargin="-120px 0px 0px 0px" />
      )}

      {isFetched && children}
    </div>
  );
};
