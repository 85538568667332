import clsx from "clsx";
import React, { ButtonHTMLAttributes } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

import { ArrowIcon } from "icons";

interface IBackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  color?: "secondary" | "primary";
  size?: "small" | "normal";
  backTo?: string;
  backToState?: NavigateOptions | undefined;
  onClick?: () => void;
}

export const BackButton = ({
  className,
  color = "primary",
  size = "small",
  backTo,
  backToState,
  onClick,
  ...restProps
}: IBackButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      return onClick();
    }

    if (backTo) {
      return navigate(backTo, backToState);
    }

    return navigate(-1);
  };

  return (
    <button
      className={clsx(
        "z-30 flex h-10 w-10 shrink-0 items-center justify-center rounded-xl duration-300",
        {
          "bg-white": color === "primary",
          "bg-blue-900 hover:bg-blue-400": color === "secondary",
        },
        className
      )}
      {...restProps}
      onClick={handleClick}
    >
      <ArrowIcon
        className={clsx({
          "w-4": size === "small",
          "w-5": size === "normal",
        })}
      />
    </button>
  );
};
