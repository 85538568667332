import { Combobox } from "@headlessui/react";
import { VirtualItem, useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { useRef } from "react";

import { Typography } from "components/Typography";
import { UserPhoto } from "components/UserPhoto";
import { OptionType } from "types/common.types";

import { FlagIcon } from "../FlagIcon";

function AutocompleteVirtualizedList({
  options,
  isCountryFlag,
  isUserPhoto,
}: {
  options: OptionType[];
  isCountryFlag?: boolean;
  isUserPhoto?: boolean;
}) {
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: options?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 43,
    overscan: 5,
  });

  return (
    <div ref={parentRef}>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow: VirtualItem) => (
          <Combobox.Option
            key={virtualRow.index}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }}
            className={({ active, selected }) =>
              clsx("relative cursor-pointer px-4 py-3 hover:bg-grey-700", {
                "bg-grey-700": active || selected,
              })
            }
            value={options?.[virtualRow.index]}
          >
            <div className="flex items-center">
              {isCountryFlag && (
                <FlagIcon code={`${options?.[virtualRow.index].value}`} className="mr-2 shrink-0" />
              )}
              {isUserPhoto && (
                <UserPhoto
                  className="mr-3.5"
                  size="extra-small"
                  userId={`${options?.[virtualRow.index].value}`}
                />
              )}
              <Typography className="leading-1 block truncate leading-[1.2]" variant="input">
                {options?.[virtualRow.index].name}
              </Typography>
            </div>
          </Combobox.Option>
        ))}
      </div>
    </div>
  );
}

export default AutocompleteVirtualizedList;
