import { FormattedMessage } from "react-intl";

import { Button } from "components/ui";

const Block4 = () => (
  <div className="py-10 lg:py-30">
    <div className="container mb-11 flex flex-col items-center">
      <h2 className="h2 mb-6 text-center lg:text-[40px]">
        <FormattedMessage id="landing.block4.title" />
      </h2>
      <p className="mb-6 text-center text-[18px] text-grey-400 lg:w-8/12">
        <FormattedMessage id="landing.block4.description" />
      </p>
      <Button className="w-44" component="Link" to="/welcome">
        <FormattedMessage id="landing.block4.button" />
      </Button>
    </div>
    <img className="w-full lg:hidden" src="images/landing-block4-mobile.png" alt="revolution" />
    <div className="mx-auto hidden max-w-[1300px] lg:flex">
      <img
        // className="mx-auto hidden max-w-[1300px] lg:flex"
        src="images/landing-block4-desktop.png"
        alt="revolution"
      />
    </div>
  </div>
);

export default Block4;
