import clsx from "clsx";
import React from "react";
import { createPortal } from "react-dom";

import { Typography } from "components/Typography";
import { useSnackBarContext } from "context/SnackbarContext";
import { CrossIcon } from "icons";

const getTitleByType = (type: string) => {
  switch (type) {
    case "error":
      return "Error";
    case "success":
      return "Success";
    case "warning":
      return "Warning";
    default:
      return "";
  }
};

export const SnackBar = () => {
  const { isShow, message, type, onClose } = useSnackBarContext();

  const titleByType = getTitleByType(type);

  return createPortal(
    <div
      className={clsx(
        "fixed right-2 top-2 z-50 flex w-80 items-center justify-between rounded-2xl py-2 pl-4 pr-2 text-white transition-[opacity] duration-300",
        {
          "bg-success": type === "success",
          "bg-error": type === "error",
          "bg-warning": type === "warning",
          "opacity-1 shadow-grey-600  pointer-events-auto shadow-md ": isShow,
          "pointer-events-none opacity-0 shadow-none": !isShow,
        }
      )}
    >
      <div className="mr-2 flex flex-col">
        {titleByType && (
          <Typography variant="h6" className="mb-1">
            {titleByType}
          </Typography>
        )}
        <Typography variant="body-lg">{message}</Typography>
      </div>
      <button className="p-2" onClick={() => onClose()}>
        <CrossIcon className="h-3" />
      </button>
    </div>,
    document.body
  );
};
