import { lazy } from "react";

import { Loadable } from "components/ui";

const SharedProfile = Loadable(lazy(() => import("pages/SharedProfile")));

const SharedProfileLoggedOutRoute = {
  path: "/:username",
  element: <SharedProfile />,
};

export default SharedProfileLoggedOutRoute;
