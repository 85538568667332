import clsx from "clsx";
import { ReactNode } from "react";

const CardContainer = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={clsx(
        "flex min-h-previewCard items-center rounded-2xl bg-white px-4 py-2 duration-300 hover:cursor-pointer lg:hover:bg-grey-700",
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default CardContainer;
