import clsx from "clsx";
import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, ReactNode } from "react";
import { Link, LinkProps as LinkRouterProps } from "react-router-dom";

import { Loader } from "components/ui";
import { ArrowIcon } from "icons";

export type ButtonVariant = "contained" | "outlined" | "text";
type ButtonSize = "small" | "medium" | "normal" | "ultra-small";
type ButtonColor = "primary" | "error" | "green";

type SharedProps = {
  className?: string;
  contentClassName?: string;
  variant?: ButtonVariant;
  withArrow?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  size?: ButtonSize;
  children: ReactNode;
  type?: "button" | "submit" | "reset";
  color?: ButtonColor;
};

type ButtonProps = SharedProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    component?: "button";
  };

type AnchorProps = SharedProps &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    component: "a";
  };

type LinkProps = SharedProps &
  LinkRouterProps & {
    component: "Link";
  };

const colors = {
  primary: {
    contained: "border-blue-600 bg-blue-600 text-white hover:bg-blue-650 hover:border-blue-650",
    outlined: "border-blue-600 bg-transparent text-blue-600 hover:bg-grey-700",
    text: "border-transparent bg-inherit text-blue-600 hover:bg-grey-700",
  },
  error: {
    contained: "border-error bg-error text-white hover:bg-errorDark",
    outlined: "border-error bg-transparent text-error hover:bg-errorLight",
    text: "border-transparent bg-inherit text-error hover:bg-errorLight",
  },
  green: {
    contained: "border-green bg-green text-white",
    outlined: "border-green bg-transparent text-green",
    text: "border-transparent bg-inherit text-green",
  },
};

const sizes = {
  "ultra-small": "h-5 !py-3 !px-2 !text-body-md",
  small: "h-10",
  medium: "h-12",
  normal: "h-15",
};

export const Button: FC<ButtonProps | AnchorProps | LinkProps> = ({
  className,
  contentClassName,
  variant = "contained",
  size = "normal",
  component = "button",
  withArrow = false,
  isLoading = false,
  disabled = false,
  children,
  type = "button",
  color = "primary",
  ...attributes
}) => {
  const Component = component === "Link" ? Link : component;

  const isButtonDisabled = disabled || isLoading;

  const attr = {
    ...attributes,
    target: component === "a" ? "_blank" : "_self",
    className: clsx(
      "group flex relative items-center py-1 rounded-2xl border text-body-lg font-extrabold duration-300",
      {
        "px-3 justify-center": !withArrow,
        "pl-7 pr-2 justify-between": withArrow,
        "pointer-events-none": isButtonDisabled,
        "border-grey-400/[.3] bg-grey-400/[.3] !text-grey-0/50":
          variant === "contained" && isButtonDisabled,
        "border-grey-500 !text-grey-200": variant === "outlined" && isButtonDisabled,
      },
      sizes[size],
      colors[color][variant],
      className
    ),
  };

  return (
    // @ts-expect-error for button/link props
    <Component disabled={isButtonDisabled} {...attr} type={type}>
      {isLoading && <Loader absolute />}
      {!isLoading && (
        <>
          <div className={clsx(contentClassName, "flex w-full items-center justify-center")}>
            {children}
          </div>
          {withArrow && (
            <div className="flex h-10 w-10 items-center justify-center rounded-xl bg-blue-500 duration-300 group-hover:bg-blue-700">
              <ArrowIcon className="w-4 rotate-180 text-white" />
            </div>
          )}
        </>
      )}
    </Component>
  );
};
