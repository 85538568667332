import clsx from "clsx";
import { FC, useEffect, useState } from "react";

import { CLOUDINARY_GET_URL } from "configs/cloudinary";
import { usePhoto } from "context/PhotoContext";

export type UserPhotoSizeType = "default" | "large" | "normal" | "small" | "extra-small";

interface IUserPhotoProps {
  className?: string;
  userId?: string;
  value?: File | string | null;
  size?: UserPhotoSizeType;
}

export const UserPhoto: FC<IUserPhotoProps> = ({ className, userId, value, size = "default" }) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>();

  const { photoKey } = usePhoto();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const imageSrc = value
      ? typeof value === "string"
        ? value
        : URL.createObjectURL(value)
      : CLOUDINARY_GET_URL + userId + `.png?lastmod=${photoKey}`;

    setImgSrc(imageSrc);
  }, [value, userId, photoKey]);

  return (
    <div
      className={clsx(className, "z-10 shrink-0", {
        // TODO rework
        "h-24 w-24": size === "default",
        "h-16 w-16": size === "large",
        "h-12 w-12": size === "normal",
        "h-10 w-10": size === "small",
        "h-6 w-6": size === "extra-small",
      })}
    >
      <img
        className={clsx("h-full w-full rounded-full object-cover", {
          "opacity-0": !isLoaded,
          "opacity-100": isLoaded,
        })}
        src={imgSrc}
        onError={() => setImgSrc("/images/default-avatar.png")}
        onLoad={() => setIsLoaded(true)}
        alt="avatar"
      />
    </div>
  );
};
