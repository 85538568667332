import { useIntl } from "react-intl";
import { useCopyToClipboard } from "usehooks-ts";

import { useSnackBarContext } from "context/SnackbarContext";

import useIsMobile from "./useIsMobile";

const useCopyLink = (username: string) => {
  const [_, copyToClipboard] = useCopyToClipboard(); // eslint-disable-line
  const { openSuccessNotification } = useSnackBarContext();
  const intl = useIntl();

  const { isMobile } = useIsMobile();

  const handleCopy = async () => {
    const shareData = {
      title: "Enviago",
      url: `/${username}`,
    };

    if (isMobile && navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        //eslint-disable-next-line
        console.error(error);
      }
    } else {
      copyToClipboard(`${process.env.REACT_APP_DOMAIN}/${username}`);
      openSuccessNotification(intl.formatMessage({ id: "copyLink.success" }));
    }
  };

  return { handleCopy };
};

export default useCopyLink;
