import clsx from "clsx";
import { FC, Fragment, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { Typography } from "components/Typography";
import { SharedLink } from "components/ui";

import { BREADCRUMBS_VALUES } from "./breadcrumbs.constants";

const getBreadcrumbLinks = (pathname: string) => {
  let newString = pathname;
  let array: string[] = [newString];

  while (newString !== "/") {
    const path = newString.slice(0, newString.lastIndexOf("/"));
    newString = path || "/";
    array = [...array, path || "/"];
  }

  return array;
};

interface IBreadcrumbsProps {
  className?: string;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ className }) => {
  const { pathname, state = {} } = useLocation();

  const breadcrumbLinks = useMemo(() => getBreadcrumbLinks(pathname).reverse(), [pathname]);

  return (
    <div className={clsx("flex flex-wrap items-center", className)}>
      {breadcrumbLinks.length > 1 &&
        breadcrumbLinks.map(
          (link, index, array) =>
            BREADCRUMBS_VALUES[link] && (
              <Fragment key={link}>
                <SharedLink
                  className={clsx(
                    "inline-flex items-center text-grey-100 duration-300 last:font-bold hover:text-blue-700"
                  )}
                  variant="subheading-md"
                  path={link}
                  state={state}
                >
                  <FormattedMessage id={BREADCRUMBS_VALUES[link]} />
                </SharedLink>
                {index < array.length - 1 && (
                  <Typography className="mx-1 inline-flex text-grey-100">/</Typography>
                )}
              </Fragment>
            )
        )}
    </div>
  );
};
