import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import React, { ReactNode, useState } from "react";

import { CrossIcon } from "icons";

interface IPopupProps {
  className?: string;
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  size?: "sm" | "lg";
}

export const Popup = ({ className, isOpen, onClose, children, size = "sm" }: IPopupProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(isOpen);

  const onClosePopup = () => {
    setIsPopupOpen(false);

    onClose && onClose();
  };

  return (
    <Dialog open={isPopupOpen} onClose={onClosePopup} className="relative z-51">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center px-6">
        <Dialog.Panel
          className={clsx(
            "relative w-full rounded-3xl bg-white px-9 pb-9 pt-12",
            `max-w-${size}`,
            className
          )}
        >
          <button className="group absolute right-2 top-1 p-5" type="button" onClick={onClosePopup}>
            <CrossIcon className="h-4 w-4 duration-300 group-hover:text-blue-700" />
          </button>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
