import clsx from "clsx";
import { FC, ReactNode, HTMLAttributes } from "react";

export type TypographyVariants =
  | "h1-xl"
  // | "h1"
  // | "h2"
  // | "h3"
  | "h4-lg"
  | "h4"
  | "h5"
  | "h6"
  | "input"
  // | "subheading-xl"
  | "subheading-lg"
  | "subheading-md"
  // | "subheading"
  // | "body-xl"
  | "body-lg"
  | "body-md"
  | "body"
  | "caption"
  | "base";

const classes = {
  "h1-xl": "text-h1-xl ",
  // h1: "text-h1",
  // h2: "text-h2",
  // h3: "text-h3",
  "h4-lg": "text-h4-lg",
  h4: "text-h4",
  h5: "text-h5",
  h6: "text-h6",
  input: "text-input",
  // "subheading-xl": "text-subheading-xl",
  "subheading-lg": "text-subheading-lg",
  "subheading-md": "text-subheading-md",
  // subheading: "text-subheading",
  // "body-xl": "text-body-xl",
  "body-lg": "text-body-lg",
  "body-md": "text-body-md",
  body: "text-body",
  caption: "text-caption",
  base: "text-base",
};

type Tags = {
  [key: string]: string;
};

const tags: Tags = {
  "h1-xl": "h1",
  // h1: "h1",
  // h2: "h2",
  // h3: "h3",
  h4: "h4",
  "h4-lg": "h4",
  h5: "h5",
  h6: "h6",
};

interface ITypographyProps extends HTMLAttributes<string> {
  component?: string;
  variant?: TypographyVariants;
  children: ReactNode;
  href?: string;
}

export const Typography: FC<ITypographyProps> = ({
  className,
  component,
  variant = "body",
  children,
  ...attributes
}) => {
  const Component = component || tags[variant] || "p";

  const attr = {
    ...attributes,
    className: clsx(className, classes[variant]),
  };

  return <Component {...attr}>{children}</Component>;
};
