/* eslint @typescript-eslint/no-explicit-any: 0 */
// Disabling all data any type

import { ISharedProfile, IUser } from "types/user";

export const parseSharedProfile = (data: any): ISharedProfile => {
  return {
    username: data.username,
    userId: data.user_id,
    profile: {
      backgroundImage: data.profile?.background_image || "",
      bioMessage: data.profile?.bio_message || "",
      pricePoints:
        data.profile?.price_point_details
          ?.map((pricePoint: any) => ({
            name: `$${pricePoint.price}` || "",
            value: +pricePoint.price,
            description: pricePoint.description,
            mediaType: pricePoint.media_type,
          }))
          .sort((a: any, b: any) => a.value - b.value) || [],
      photo: data.profile?.profile_photo || "",
    },
  };
};

export const parseUser = (data: any): IUser => ({
  email: data.email,
  accountStatus: data.account_status,
  emailStatus: data.email_status,
  country: data.country,
  fullName: data.full_name,
  photo: data.photo,
  phone: data.phone,
  userId: data.user_id,
  ...parseSharedProfile(data),
});

export const parseContactUser = (data: any): ISharedProfile => ({
  username: data.contact_username,
  userId: data.contact_user_id,
});
