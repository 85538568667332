import clsx from "clsx";
import { formatDistance, parseISO } from "date-fns";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import CardContainer from "components/CardContainer";
import { TransactionAmount } from "components/TransactionAmount";
import { Typography } from "components/Typography";
import Chip from "components/ui/Chip";
import { WITHDRAWAL_TRANSACTION_STATUS } from "constants/transaction";
import { useTheme } from "context/ThemeContext";
import { BankIcon } from "icons";
import { DATE_FNS_LOCALE } from "locales";
import { ITransaction } from "types/transaction";

const statusInfo: Record<
  WITHDRAWAL_TRANSACTION_STATUS,
  { color: "red" | "green" | "grey"; statusId: string }
> = {
  [WITHDRAWAL_TRANSACTION_STATUS.CANCELLED]: { color: "red", statusId: "cancelledStatus" },
  [WITHDRAWAL_TRANSACTION_STATUS.COMPLETE]: { color: "green", statusId: "completeStatus" },
  [WITHDRAWAL_TRANSACTION_STATUS.PROCESSING]: {
    color: "grey",
    statusId: "processingStatus",
  },
  [WITHDRAWAL_TRANSACTION_STATUS.REJECTED]: { color: "red", statusId: "rejectedStatus" },
  [WITHDRAWAL_TRANSACTION_STATUS.FAILED]: { color: "red", statusId: "failedStatus" },
  [WITHDRAWAL_TRANSACTION_STATUS.CREATED]: { color: "green", statusId: "createdStatus" },
  [WITHDRAWAL_TRANSACTION_STATUS.UNKNOWN]: { color: "grey", statusId: "unknownStatus" },
};

export interface IWithdrawalTransactionCardProps {
  className?: string;
  data: ITransaction;
}

export const WithdrawalTransactionCard: FC<IWithdrawalTransactionCardProps> = ({
  className,
  data,
}) => {
  const { locale } = useTheme();
  const navigate = useNavigate();

  const { createdAt, status } = data;

  const statusData = statusInfo[status] || statusInfo.unknown;

  const handleNavigateToDetails = () => {
    navigate(`/withdraw-funds/withdrawal-details?id=${createdAt}`);
  };

  return (
    <CardContainer
      className={clsx(className, "flex-col !items-end")}
      onClick={handleNavigateToDetails}
    >
      <div className="flex h-full w-full items-center">
        <div className="mr-3.5 p-0.5">
          <BankIcon className="h-9 w-9 text-blue-700" />
        </div>
        <div className="overflow-hidden">
          <Typography className="truncate font-semibold text-blue-0" variant="body-lg">
            Withdrawal
          </Typography>
          <Typography className="text-grey-0" variant="caption">
            {formatDistance(parseISO(createdAt), new Date(), {
              addSuffix: true,
              locale: DATE_FNS_LOCALE[locale],
            })}
          </Typography>
        </div>
        <div className="ml-auto flex flex-col items-end">
          <Chip color={statusData.color} className="shrink-0 whitespace-nowrap">
            <FormattedMessage id={statusData.statusId} />
          </Chip>
          <TransactionAmount
            className="text-blue-700"
            amount={data.amount}
            variant="h6"
            amountPrefix="-"
          />
        </div>
      </div>
    </CardContainer>
  );
};
