import clsx from "clsx";
import {
  FC,
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";

interface IInputPinProps {
  className?: string;
  inputClassName?: string;
  pinLength?: number;
  isResetPin: boolean;
  setIsResetPin: (value: boolean) => void;
  onChange: (value: string) => void;
}

export const InputPin: FC<IInputPinProps> = ({
  className,
  inputClassName,
  pinLength = 6,
  isResetPin = false,
  setIsResetPin,
  onChange,
}) => {
  const emptyPin = useMemo(
    () =>
      [...Array(pinLength).keys()].map((key) => ({
        id: key,
        value: "",
      })),
    [pinLength]
  );

  const [pin, setPin] = useState(emptyPin);
  const [activeNumber, setActiveNumber] = useState(0);

  useEffect(() => {
    if (isResetPin) {
      setPin(emptyPin);
      onChange("");
      setIsResetPin(false);
    }
  }, [isResetPin, emptyPin, onChange, setIsResetPin]);

  const autoFocus = useCallback((el: HTMLInputElement) => (el ? el.focus() : null), []);

  const handleOnFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
    const eventTarget = e.target;

    setActiveNumber(+eventTarget.id);
    eventTarget.select();
  };

  const handleOnPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const eventTarget = e.currentTarget;

    if (+eventTarget.id === 0) {
      const copyText = e.clipboardData.getData("text").replace(/[^0-9]+/g, "");

      const newPin = pin.map((pinItem, i) => {
        if (copyText[i]) {
          return { ...pinItem, value: copyText[i] };
        }

        return pinItem;
      });

      const pinValues = newPin.map((pinItem) => pinItem.value).join("");

      setPin(newPin);
      onChange(pinValues);

      if (copyText.length >= pinLength) {
        return setActiveNumber(pinLength - 1);
      }

      setActiveNumber(copyText.length);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    const eventTarget = e.target;

    const newPin = pin.map((pinItem) => {
      if (pinItem.id === +eventTarget.id) {
        return { ...pinItem, value: eventTarget.value };
      }

      return pinItem;
    });

    const pinValues = pin
      .map((pinItem) => {
        if (pinItem.id === +eventTarget.id) {
          return eventTarget.value;
        }

        return pinItem.value;
      })
      .join("");

    setPin(newPin);
    onChange(pinValues);
  };

  const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const eventTarget = e.currentTarget;

    if (e.key === "Backspace") {
      if (+eventTarget.id > 0) {
        setActiveNumber(activeNumber - 1);
      }
    } else {
      const isNumericKey = [...Array(10).keys()].some((key) => +e.key === key);

      if (isNumericKey && eventTarget.value && eventTarget.value === pin[+eventTarget.id].value) {
        if (+eventTarget.id !== pinLength - 1) {
          setActiveNumber(activeNumber + 1);
        }
      }
    }
  };

  return (
    <div className={clsx("flex items-center gap-1.5", className)}>
      {[...Array(pinLength).keys()].map((key) => (
        <input
          key={key}
          className={clsx(inputClassName, "input rounded-lg text-center focus:border-blue-700", {
            "h-15 w-16 sm:h-17 sm:w-20": pinLength <= 4,
            "h-10 w-10 sm:h-11 sm:w-12 xl:h-17 xl:w-20": pinLength > 4,
          })}
          id={`${key}`}
          value={pin[key].value}
          inputMode="decimal"
          maxLength={1}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          onChange={handleOnChange}
          onKeyUp={handleOnKeyUp}
          ref={key === activeNumber ? autoFocus : undefined}
        />
      ))}
    </div>
  );
};
