import { lazy } from "react";

import { AuthLayout } from "components/Layout/AuthLayout";
import { Loadable } from "components/ui";

const NotFound = Loadable(lazy(() => import("pages/NotFound")));

const NotFoundRoute = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "*",
      element: <NotFound />,
    },
  ],
};

export default NotFoundRoute;
