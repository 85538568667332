import { IconProps } from "./types";

export function CreditCardIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <g id="Credit_Card">
        <path d="M27 26H5c-2 0-3-1-3-3V9c0-2 1-3 3-3h22c2 0 3 1 3 3v14c0 2-1 3-3 3z" fill="white" />
        <path fill="#fff" d="M2 11h28v4H2z" />
        <path
          fill="currentColor"
          d="M27 5H5C3 5 1 7 1 9v14c0 2 2 4 4 4h22c2 0 4-2 4-4V9c0-2-2-4-4-4zM3 12h26v2H3v-2zm2-5h22l2 2v1H3V9l2-2zm22 18H5l-2-2v-7h26v7l-2 2z"
        />
        <path
          fill="currentColor"
          d="M6 19h3a1 1 0 0 0 0-1H6a1 1 0 0 0 0 1zM12 21H6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z"
        />
      </g>
    </svg>
  );
}
