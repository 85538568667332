import { IconProps } from "./types";

export function ShapeIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49" {...props}>
      <path
        fill="#313381"
        d="M24.5 0C10.969 0 0 10.969 0 24.5S10.969 49 24.5 49 49 38.031 49 24.5 38.031 0 24.5 0zm8.438 32.938a3.592 3.592 0 0 1-5.1 0L25 30.064l-2.837 2.874a3.592 3.592 0 0 1-5.1 0 3.592 3.592 0 0 1 0-5.1L19.935 25l-2.874-2.837a3.592 3.592 0 0 1 0-5.1 3.592 3.592 0 0 1 5.1 0L25 19.935l2.837-2.874a3.592 3.592 0 0 1 5.1 0 3.592 3.592 0 0 1 0 5.1L30.065 25l2.874 2.837a3.592 3.592 0 0 1 0 5.1z"
      />
    </svg>
  );
}
