import { Listbox } from "@headlessui/react";
import clsx from "clsx";
import { FocusEvent, forwardRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Typography } from "components/Typography";
import { Input, Loader } from "components/ui";
import { ChevronIcon } from "icons";
import { OptionType } from "types/common.types";

interface ISelectProps {
  className?: string;
  options: OptionType[];
  placeholder: string;
  value?: OptionType | null;
  onChange: (...event: any[]) => void; //eslint-disable-line
  onBlur?: (...event: any[]) => void; //eslint-disable-line
  onFocus?: (...event: any[]) => void; //eslint-disable-line
  // onChange, onBlur, onFocus from react-hook-form may vary
  error?: string;
  isErrorWithoutMessage?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  shouldLocalizeName?: boolean;
}

export const Select = forwardRef<HTMLInputElement, ISelectProps>(
  (
    {
      className,
      options,
      placeholder,
      value,
      onChange,
      onBlur,
      onFocus,
      error,
      isErrorWithoutMessage,
      disabled,
      isLoading,
      shouldLocalizeName,
    },
    ref
  ) => {
    const intl = useIntl();
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
      onBlur && onBlur(e);
      setIsFocused(false);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
      onFocus && onFocus(e);
      setIsFocused(true);
    };

    return (
      <Listbox
        as="div"
        className={clsx("relative  w-full", className)}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        {({ open }) => (
          <>
            <Input
              ref={ref}
              value={
                shouldLocalizeName && value?.name
                  ? intl.formatMessage({ id: value.name })
                  : value?.name
              }
              isSelected={open}
              placeholder={placeholder}
              customInput={Listbox.Button}
              error={isFocused ? "" : error}
              disabled={disabled}
              isErrorWithoutMessage={isErrorWithoutMessage}
              Icon={options.find((el) => el.value === value?.value)?.icon}
            />
            <button className="pointer-events-none absolute inset-y-0 right-0 w-11 truncate px-4">
              <ChevronIcon
                className={clsx("h-2 w-3 text-blue-200 duration-300", {
                  "rotate-180": open,
                })}
              />
            </button>
            {isLoading && <Loader absolute />}
            <div className="relative z-50 w-full">
              <Listbox.Options className="absolute top-2 z-10 max-h-56 w-full overflow-y-auto rounded-2xl border border-grey-500 bg-white py-2 shadow-lg xl:max-h-72">
                {options.map(({ icon, ...item }) => (
                  <Listbox.Option
                    key={item.value + item.name}
                    className={({ active }) =>
                      clsx("relative flex cursor-pointer px-4 py-3 hover:bg-grey-700", {
                        "bg-grey-700": item.value === value?.value || active,
                      })
                    }
                    value={item}
                  >
                    {icon && icon}
                    <Typography className="block truncate leading-[1.2]" variant="input">
                      {shouldLocalizeName ? <FormattedMessage id={item.name} /> : item.name}
                    </Typography>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </>
        )}
      </Listbox>
    );
  }
);
