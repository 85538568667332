import { FormattedMessage } from "react-intl";

import { LanguageSwitcher } from "components/ui";
import { NotifyIcon } from "icons";

const VerifySuccessMobile = () => {
  return (
    <div className="absolute inset-0 z-[9999999] overflow-hidden bg-white p-10 pt-22 text-center">
      <LanguageSwitcher className="!absolute right-6 top-6" />
      <h1 className="h6 mb-10 font-bold">
        <FormattedMessage id="pages.verifyMobile.title" />
      </h1>
      <h2 className="h2 mb-6 font-bold">
        <FormattedMessage id="pages.verifyMobile.subTitle" />
      </h2>
      <p className="subtitle mb-16 text-gray-500">
        <FormattedMessage id="pages.verifyMobile.description" />
      </p>
      <NotifyIcon className="mx-auto mb-5 h-36 w-36" />
    </div>
  );
};

export default VerifySuccessMobile;
