import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { fetchRemindTransactionPayment } from "api/transactionsAPI";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Button, Popup } from "components/ui";
import { useAuth } from "context/AuthContext";
import { useSnackBarContext } from "context/SnackbarContext";

const UserTransactionCardRemindButton = ({ paymentId }: { paymentId: string }) => {
  const intl = useIntl();
  const {
    user: { userId },
  } = useAuth();
  const { openErrorNotification, openSuccessNotification } = useSnackBarContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleRemindClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setIsOpenDialog(true);
  };

  const handleRemind = async () => {
    try {
      setIsLoading(true);

      await fetchRemindTransactionPayment({ userId, paymentId });

      setIsOpenDialog(false);

      openSuccessNotification(intl.formatMessage({ id: "snackBar.requestRemind.success" }));
    } catch (error) {
      openErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button size="ultra-small" onClick={handleRemindClick}>
        <FormattedMessage id="buttons.remind" />
      </Button>
      {isOpenDialog && (
        <Popup isOpen={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
          <ConfirmDialog
            title="sendReminderDialog.title"
            isLoading={isLoading}
            onCancel={() => setIsOpenDialog(false)}
            onSubmit={handleRemind}
          />
        </Popup>
      )}
    </>
  );
};

export default UserTransactionCardRemindButton;
