import { IconProps } from "./types";

export function ChevronIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 8" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.526 7.26a.558.558 0 0 1-.183-.122L0 .792.792 0l5.951 5.951L12.694 0l.792.792-6.347 6.346a.558.558 0 0 1-.613.122z"
      />
    </svg>
  );
}
