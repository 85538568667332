import { formatDistance, parseISO } from "date-fns";

import { STORAGE_PREFIX } from "configs/app.config";
import { DATE_FNS_LOCALE, LanguageType } from "locales";

const normalizeParam = (param: string | null) =>
  param !== null ? decodeURIComponent(param).replace(/ /g, "+") : "";

export const decodeUrlParams = ({ url, paramsArr }: { url: string; paramsArr: string[] }) => {
  const queryParams = new URLSearchParams(url);

  return paramsArr.reduce(
    (acc, param) => ({
      ...acc,
      [param]: normalizeParam(queryParams.get(param)),
    }),
    {} as Record<string, string>
  );
};

export const numberToLocaleString = (number: number, options?: Record<string, string>) =>
  number.toLocaleString("en-US", options);

export const getShortDateDistance = (createdAt: string, locale: LanguageType) => {
  const distance = formatDistance(parseISO(createdAt), new Date(), {
    locale: DATE_FNS_LOCALE[locale],
  });
  const splitted = distance.split(" ");

  const numb = splitted.find((el) => +el);

  if (!numb) {
    return distance;
  }

  const period = splitted[splitted.length - 1].charAt(0);

  return numb + period;
};

export const getLocalStorageValueByKey = (key: string) =>
  JSON.parse(localStorage.getItem(`${STORAGE_PREFIX}${key}`) || "null");
