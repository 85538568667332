import CanonicalHelmetLink from "components/CanonicalHelmetLink";
import FooterLoggedOut from "components/FooterLoggedOut";
import HeaderLoggedOut from "components/HeaderLoggedOut";
import JoinFutureEarnings from "components/JoinFutureEarnings";
import MetaOgHelmetTags from "components/MetaOgHelmetTags";
import useToggleClassName from "hooks/useToggleClassName";

import Block1 from "./Block1";
import Block2 from "./Block2";
import Block3 from "./Block3";
import Block4 from "./Block4";

const Landing = () => {
  useToggleClassName("landing");

  return (
    <div className="bg-grey-900">
      <MetaOgHelmetTags
        title="Enviago | Envía y Recibe Dinero de Forma Rápida y Segura"
        description="Enviago es una forma rápida y segura de enviar y recibir dinero de fans, amigos o familiares. ¡Únete ahora!"
        ogTitle="Enviago | Envía y Recibe Dinero de Forma Rápida y Segura"
        ogDescription="Enviago es una forma rápida y segura de enviar y recibir dinero de fans, amigos o familiares. ¡Únete ahora!"
      />
      <CanonicalHelmetLink />
      <HeaderLoggedOut />
      <Block1 />
      <Block2 />
      <Block3 />
      <Block4 />
      <JoinFutureEarnings />
      <FooterLoggedOut />
    </div>
  );
};

export default Landing;
