import clsx from "clsx";
import { Link } from "react-router-dom";

import { Typography, TypographyVariants } from "components/Typography";

interface ISharedLinkProps {
  className?: string;
  path: string;
  state?: any; // eslint-disable-line
  // useLocation state has any type
  component?: string;
  variant?: TypographyVariants;
  children: React.ReactNode;
}

export const SharedLink = ({
  className,
  path,
  state,
  component,
  variant = "caption",
  children,
}: ISharedLinkProps) => (
  <Link to={path} state={state} className={clsx(className, "text-blue-700 hover:underline")}>
    <Typography variant={variant} component={component}>
      {children}
    </Typography>
  </Link>
);
