import clsx from "clsx";
import { FC } from "react";

import { Typography } from "components/Typography";
import { CirclesIcon } from "icons";

interface IProfileBackgroundProps {
  className?: string;
  textWrapperClassName?: string;
  fullName: string;
  username: string;
  image?: string;
}

export const ProfileBackground: FC<IProfileBackgroundProps> = ({
  className,
  textWrapperClassName,
  fullName,
  username,
  image,
}) => (
  <div className={clsx("relative h-48 w-full overflow-hidden rounded-b-3xl", className)}>
    {!image && <div className={clsx("h-full w-full bg-blue-700")} />}
    {image && (
      <img className="block h-full w-full object-cover" alt="profile-background" src={image} />
    )}
    <div className={clsx("absolute text-white", textWrapperClassName)}>
      <Typography className="mb-2.5" variant="h5">
        {fullName}
      </Typography>
      <Typography className="opacity-70" variant="h6">
        {username}
      </Typography>
    </div>
    <CirclesIcon className="absolute -right-8 -top-4 w-48" />
  </div>
);
