import clsx from "clsx";
import { TextareaHTMLAttributes, forwardRef } from "react";

interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  placeholder?: string;
  isLoading?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
  ({ placeholder, className, isLoading, ...attr }, ref) => {
    return (
      <textarea
        className={clsx(
          "h-36 w-full resize-none rounded-2xl border bg-grey-900 px-5 py-6 text-body-lg font-semibold focus:border-blue-600 focus:outline-none focus:ring-blue-600",
          className,
          { "opacity-50": isLoading }
        )}
        disabled={isLoading}
        placeholder={placeholder}
        ref={ref}
        {...attr}
      />
    );
  }
);
