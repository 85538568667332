import { IconProps } from "./types";

export function InfoIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 40 40" {...props}>
      <path fill="none" d="M0 0h48v48H0z" />
      <path
        fill="currentColor"
        d="M22 34h4V22h-4v12zm2-30C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"
      />
    </svg>
  );
}
