import { Combobox, ComboboxInputProps, Listbox } from "@headlessui/react";
import clsx from "clsx";
import {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  forwardRef,
  useState,
} from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import { Typography } from "components/Typography";
import { PasswordHideIcon, PasswordShowIcon } from "icons";

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  ComboboxInputProps<"input", string> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
    // error?: string;
    isErrorWithoutMessage?: boolean;
    isSelected?: boolean;
    customInput?: any; //eslint-disable-line
    // no types in lib for the components we put here
    prefix?: string;
    preIcon?: React.ReactNode;
    Icon?: React.ReactNode;
    disabled?: boolean;
    isNumbersOnly?: boolean;
    maxDigits?: number;
    errorClassName?: string;
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      errorClassName,
      value,
      placeholder,
      error,
      isErrorWithoutMessage,
      customInput,
      isSelected,
      prefix,
      preIcon,
      Icon,
      type,
      disabled,
      isNumbersOnly = false,
      maxDigits,
      onBlur,
      onFocus,
      onChange,
      ...attributes
    },
    ref
  ) => {
    const [isPasswordShow, setIsPasswordShow] = useState(false);

    const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
      onBlur && onBlur(e);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
      onFocus && onFocus(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      // maybe make NumbersInput component
      const isTwoDecimals = e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/);

      if (isNumbersOnly && (isNaN(+e.target.value) || !isTwoDecimals)) return;

      if (maxDigits) {
        e.target.value = e.target.value.slice(0, maxDigits);
      }

      onChange && onChange(e);
    };

    const handlePasswordShow = () => {
      setIsPasswordShow(true);
    };

    const handlePasswordHide = () => {
      setIsPasswordShow(false);
    };

    const attr = {
      ...attributes,
      className: clsx("input w-full pb-4 h-17 px-4 pt-8 rounded-2xl truncate", {
        "border-grey-500 focus:border-blue-600": !error,
        "border-red focus:border-red": error || isErrorWithoutMessage,
        "pr-11": customInput,
        "pr-12": type === "password",
        "pl-9 group": prefix,
        "pl-11 group": preIcon,
        "bg-grey-900/80": disabled,
      }),
      type,
    };

    return (
      <div className={clsx("flex w-full flex-col", className)}>
        <div className="relative flex w-full flex-col">
          <label className="group relative text-black">
            <Typography
              className={clsx(
                "pointer-events-none absolute left-4 right-2 top-1/2 z-10 -translate-y-1/2 truncate font-semibold text-black/30 transition-all group-focus-within:top-2 group-focus-within:translate-y-0 group-focus-within:text-blue-600",
                {
                  "top-2 translate-y-0 text-blue-600": value || isSelected || value === 0,
                }
              )}
              variant="caption"
            >
              {placeholder}
            </Typography>
            {!customInput && (
              <input
                ref={ref}
                {...attr}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
                type={type === "password" && isPasswordShow ? "text" : type}
                inputMode={isNumbersOnly ? "numeric" : "text"}
              />
            )}
            {customInput === Combobox.Input && (
              <Combobox.Input
                ref={ref}
                {...attr}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            )}
            {customInput === Listbox.Button && (
              <Listbox.Button
                ref={ref as Ref<HTMLButtonElement> | undefined}
                {...attr}
                className={clsx(attr.className, "flex")}
              >
                {Icon && Icon}
                {value && `${value}`}
              </Listbox.Button>
            )}
            {preIcon && (
              <div className="pointer-events-none absolute bottom-4 left-4 top-8 z-10 flex items-center">
                {preIcon}
              </div>
            )}

            {prefix && (
              <Typography
                className={clsx(
                  "pointer-events-none absolute bottom-3.5 left-4 z-10 text-blue-100 opacity-50 group-focus-within:block",
                  {
                    hidden: !value,
                    block: value,
                  }
                )}
                variant="h6"
              >
                {prefix}
              </Typography>
            )}
          </label>
          {type === "password" && (
            <button
              type="button"
              className="absolute right-5 top-1/2 z-10 flex h-6 w-6 -translate-y-1/2 items-center justify-center"
              onClick={isPasswordShow ? handlePasswordHide : handlePasswordShow}
              tabIndex={-1}
            >
              {isPasswordShow && <PasswordHideIcon className="h-4 w-4" />}
              {!isPasswordShow && <PasswordShowIcon className="h-4 w-4" />}
            </button>
          )}
        </div>
        {error && (
          <Typography variant="caption" className={clsx(errorClassName, "mt-1 text-red")}>
            {error as ReactNode}
          </Typography>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";
