import { IconProps } from "./types";

export function CashRequestIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31" {...props}>
      <g fill="currentColor">
        <path d="M28.07 11.774a28.495 28.495 0 0 0-6.596.165c-2.737.405-4.69 1.124-6.428 1.754-1.968.72-3.52 1.274-5.658 1.184-1.63-.075-3.29-.554-4.92-1.394a2.74 2.74 0 0 0-2.676.06C.977 14.023.5 14.847.5 15.762v11.033c0 1.079.692 2.053 1.722 2.428 2.153.78 4.306 1.184 6.443 1.184.246 0 .492 0 .738-.015 2.722-.12 4.644-.84 6.504-1.544 1.769-.66 3.445-1.304 5.798-1.394 1.722-.075 3.505.165 5.29.69.83.255 1.737.105 2.429-.39.676-.495 1.076-1.244 1.076-2.069V14.383c0-1.35-1.03-2.459-2.414-2.579l-.016-.03zm.108 13.881c0 .135-.077.21-.138.255a.475.475 0 0 1-.37.06c-2.029-.614-4.074-.9-6.073-.794-2.722.12-4.66.84-6.52 1.544-1.768.66-3.444 1.289-5.782 1.394-2.045.09-4.151-.255-6.273-1.02a.352.352 0 0 1-.215-.33V15.733c0-.15.092-.24.169-.285.03-.03.107-.06.215-.06a.35.35 0 0 1 .2.045c1.937 1.004 3.92 1.559 5.904 1.664 2.615.135 4.537-.585 6.582-1.32 1.614-.584 3.444-1.259 5.95-1.633a25.388 25.388 0 0 1 6.044-.15.347.347 0 0 1 .322.345V25.64l-.015.015zM12.522 5.361l1.149 1.108V1.56c0-.63.542-1.154 1.196-1.154.654 0 1.196.523 1.196 1.154v4.91l1.15-1.108c.238-.23.541-.338.845-.338.303 0 .606.107.845.338a1.132 1.132 0 0 1 0 1.631l-3.19 3.077c-.112.108-.24.185-.383.246a1.166 1.166 0 0 1-.91 0 1.242 1.242 0 0 1-.383-.246l-3.19-3.077a1.132 1.132 0 0 1 0-1.63 1.235 1.235 0 0 1 1.69 0h-.015z" />
        <path d="M15.5 16.657a3.756 3.756 0 0 0-3.75 3.75 3.756 3.756 0 0 0 3.75 3.75 3.756 3.756 0 0 0 3.75-3.75 3.756 3.756 0 0 0-3.75-3.75zm0 5a1.26 1.26 0 0 1-1.25-1.25c0-.683.567-1.25 1.25-1.25s1.25.567 1.25 1.25a1.26 1.26 0 0 1-1.25 1.25zm8.875-6.25h-1.5c-.615 0-1.125.567-1.125 1.25 0 .684.51 1.25 1.125 1.25h1.5c.615 0 1.125-.566 1.125-1.25 0-.683-.51-1.25-1.125-1.25zm-17.5 7.5h-1.5c-.615 0-1.125.567-1.125 1.25 0 .684.51 1.25 1.125 1.25h1.5c.615 0 1.125-.566 1.125-1.25 0-.683-.51-1.25-1.125-1.25z" />
      </g>
    </svg>
  );
}
