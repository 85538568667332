import { IconProps } from "./types";

export function CashIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g fill="currentColor">
        <path d="M18.38 7.081a18.273 18.273 0 0 0-4.397.114c-1.825.28-3.127.78-4.285 1.216-1.313.5-2.348.884-3.773.821-1.086-.052-2.194-.384-3.28-.966a1.766 1.766 0 0 0-1.784.041A1.784 1.784 0 0 0 0 9.846v7.65a1.8 1.8 0 0 0 1.148 1.683c1.435.54 2.87.821 4.295.821.164 0 .328 0 .492-.01 1.815-.084 3.096-.582 4.337-1.07 1.179-.458 2.296-.905 3.864-.968 1.148-.051 2.338.115 3.527.479A1.79 1.79 0 0 0 20 16.726V8.89c0-.935-.687-1.704-1.61-1.787l-.01-.021zm.072 9.624a.212.212 0 0 1-.092.177.307.307 0 0 1-.246.042c-1.353-.427-2.717-.624-4.05-.551-1.814.083-3.106.582-4.346 1.07-1.179.458-2.296.894-3.854.967-1.364.062-2.768-.177-4.183-.707-.082-.031-.143-.125-.143-.229v-7.65c0-.103.061-.165.112-.197.02-.02.072-.041.144-.041.041 0 .082 0 .133.031 1.292.696 2.614 1.08 3.937 1.154 1.742.093 3.024-.406 4.387-.915 1.077-.405 2.296-.873 3.967-1.133a16.28 16.28 0 0 1 4.03-.104c.122.01.214.115.214.24v7.836l-.01.01zM8.387 3.532l.787-.775v3.435a.82.82 0 0 0 .82.808.82.82 0 0 0 .82-.808V2.757l.789.775a.818.818 0 0 0 .58.237.818.818 0 0 0 .58-.237.804.804 0 0 0 0-1.141L10.573.237a.85.85 0 0 0-.262-.172.784.784 0 0 0-.624 0 .85.85 0 0 0-.262.172L7.238 2.39a.804.804 0 0 0 0 1.141.835.835 0 0 0 1.16 0h-.011z" />
        <path d="M10.5 11A2.504 2.504 0 0 0 8 13.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5-1.122-2.5-2.5-2.5zm0 3.333a.84.84 0 0 1-.833-.833.84.84 0 0 1 .833-.833.84.84 0 0 1 .833.833.84.84 0 0 1-.833.833zM16.1 10h-1.2c-.492 0-.9.453-.9 1 0 .547.408 1 .9 1h1.2c.492 0 .9-.453.9-1 0-.547-.408-1-.9-1zm-12 5H2.9c-.492 0-.9.453-.9 1 0 .547.408 1 .9 1h1.2c.492 0 .9-.453.9-1 0-.547-.408-1-.9-1z" />
      </g>
    </svg>
  );
}
