import { IconProps } from "./types";

export function LogoIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202 49" {...props}>
      <g fill="currentColor">
        <path d="M10.112 21.055h10.003v-.082c0-.766-.544-1.595-1.631-2.489-.997-.51-2.012-.765-3.044-.765-2.954 0-4.73 1.112-5.328 3.336zM15.113 9.46c7.63 0 12.486 3.974 14.57 11.922.362 1.513.544 3.381.544 5.605v.52H10.22v.11c.598 1.53 1.467 2.579 2.609 3.144 1.07.565 2.084.847 3.044.847h.381c1.269 0 2.483-.729 3.642-2.187h9.786v.11c-3.606 6.38-8.173 9.57-13.7 9.57-5.146 0-9.097-1.536-11.851-4.608C1.377 31.423 0 28.073 0 24.445c0-4.21 1.45-7.743 4.35-10.595 2.899-2.853 6.487-4.316 10.763-4.39zM60.62 38.281H50.4V23.734c0-2.716-.942-4.393-2.827-5.03a5.668 5.668 0 0 0-.979-.11h-.652c-1.92 0-3.19 1.13-3.805 3.39a3.883 3.883 0 0 0-.11.985V38.28H31.7V10.39h10.329v3.5c2.464-2.99 5.255-4.485 8.372-4.485h.761c4.476 0 7.484 2.26 9.025 6.781.29 1.368.435 2.607.435 3.72V38.28zM59.293 10.39h12.069l4.24 14.22 4.458-14.22h11.743c-7.684 18.467-11.526 27.764-11.526 27.891h-9.46l-11.524-27.89zm43.794 0v27.891H92.214v-27.89h10.873zM92.214 7.657V0h10.873v7.656H92.214zm23.599 17.171c0 1.659.18 2.953.543 3.883.653 1.531 1.957 2.297 3.915 2.297.38-.073.743-.11 1.087-.11 2.32-.455 3.48-2.552 3.48-6.289-.073-2.752-.327-4.32-.762-4.703 0-.93-1.16-1.513-3.48-1.75h-.652c-.525 0-1.178.11-1.957.328-1.45.967-2.174 2.79-2.174 5.47v.874zm1.304 14.164c-1.74 0-3.479-.328-5.219-.984-5.074-2.57-7.61-7.219-7.61-13.946v-.328c0-2.716.615-5.158 1.848-7.328 2.664-4.083 6.143-6.125 10.438-6.125h1.413c2.302 0 4.494.875 6.578 2.625v-2.515h10.873l-.027 27.89h-10.9v-1.914h-.109c-2.084 1.75-4.512 2.625-7.285 2.625z" />
        <path
          d="M149.686 9.68c2.772 0 5.2.875 7.285 2.625h.108V10.39h10.9v23.132c0 6.763-2.328 11.284-6.985 13.563-2.537.875-5.074 1.312-7.611 1.312-5.51 0-9.297-.911-11.363-2.734-2.065-1.823-3.098-4.193-3.098-7.11h10.438c.271 1.605 1.105 2.407 2.5 2.407 1.903 0 3.249-.56 4.037-1.682.788-1.12 1.182-2.31 1.182-3.568v-1.313c-2.047 1.75-4.222 2.625-6.523 2.625h-1.414c-4.295 0-7.774-2.041-10.438-6.125-1.232-2.169-1.848-4.612-1.848-7.328v-.328c0-5.815 2.537-10.008 7.61-12.578 1.74-.656 3.48-.984 5.22-.984zm-1.305 12.797v.875c0 2.68.725 4.502 2.175 5.468.779.22 1.431.328 1.957.328h.652c2.32-.237 3.48-.82 3.48-1.75.434-.382.688-1.95.76-4.703 0-2.843-1.16-4.484-3.479-4.922a5.77 5.77 0 0 1-1.087-.109c-1.957 0-3.262.766-3.914 2.297-.363.93-.544 1.768-.544 2.516zm37.407-13.563c5.69 0 10.293 2.48 13.808 7.438 1.378 2.497 2.066 4.867 2.066 7.109v1.531c0 3.956-1.776 7.565-5.327 10.828-3.135 2.552-6.76 3.828-10.873 3.828-5.763 0-10.33-2.406-13.7-7.218-1.522-2.425-2.283-5.05-2.283-7.875v-.438c0-5.541 2.681-9.953 8.045-13.234 2.556-1.313 5.31-1.969 8.264-1.969zm-4.675 14.766v1.422c0 3.335 1.05 5.231 3.153 5.687l1.522.11c1.92 0 3.153-.766 3.697-2.297.435-1.313.652-2.771.652-4.375 0-4.375-1.486-6.563-4.458-6.563h-.326c-1.758 0-2.954.802-3.588 2.406-.435 1.076-.652 2.279-.652 3.61z"
          fillOpacity=".8"
        />
      </g>
    </svg>
  );
}
