import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import instance, { unauthorizedApiInstance } from "api/instance";
import { EXPIRED_SESSION_PATH } from "configs/app.config";
import { useAuth } from "context/AuthContext";
import { useTheme } from "context/ThemeContext";

export const useInterceptor = () => {
  const navigate = useNavigate();

  const { locale } = useTheme();

  const { clearAuthData } = useAuth();

  useEffect(() => {
    instance.defaults.params = { lang: locale };
    unauthorizedApiInstance.defaults.params = { lang: locale };
  }, [locale]);

  useEffect(() => {
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          await clearAuthData();

          navigate(EXPIRED_SESSION_PATH);
        }

        if (error.code !== "ERR_CANCELED") {
          return Promise.reject(error);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
