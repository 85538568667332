import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Button } from "components/ui";
import { TRANSACTION_STATUS } from "constants/transaction";
import { ITransaction, TRANSACTION_TYPE } from "types/transaction";

import UserTransactionCardCancelButton from "./UserTransactionCardCancelButton";
import UserTransactionCardRemindButton from "./UserTransactionCardRemindButton";

const UserTransactionCardRequestedStatus = ({
  transaction: {
    paymentStatus,
    transactionType,
    receiverUsername,
    receiverUserId,
    amount,
    paymentId,
  },
}: {
  transaction: ITransaction;
}) => {
  const navigate = useNavigate();

  const handleSendPayment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    navigate("/payment/send", {
      state: {
        receiver: {
          userId: receiverUserId,
          username: receiverUsername,
        },
        amount,
        paymentId,
      },
    });
  };

  return (
    <>
      {paymentStatus === TRANSACTION_STATUS.REQUESTED &&
        transactionType === TRANSACTION_TYPE.RECEIVER && (
          <div className="mt-1 flex">
            <UserTransactionCardCancelButton paymentId={paymentId} />
            <UserTransactionCardRemindButton paymentId={paymentId} />
          </div>
        )}
      {paymentStatus === TRANSACTION_STATUS.REQUESTED &&
        transactionType === TRANSACTION_TYPE.SENDER && (
          <div className="mt-1 flex">
            <UserTransactionCardCancelButton paymentId={paymentId} />
            <Button size="ultra-small" className="whitespace-nowrap" onClick={handleSendPayment}>
              <FormattedMessage id="buttons.sendPayment" />
            </Button>
          </div>
        )}
    </>
  );
};

export default UserTransactionCardRequestedStatus;
