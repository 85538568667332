import { IconProps } from "./types";

export function PlaneIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path id="a" d="M0 0h24v24H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g
          mask="url(#b)"
          stroke="#000"
          strokeDasharray="0,0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M5.58 11.59 3.64 4.88c-.24-.82.6-1.55 1.38-1.18l15.1 6.99c.77.36.77 1.46 0 1.81l-15.1 6.99a1 1 0 0 1-1.38-1.19l1.94-6.71zm0 0h4.79" />
        </g>
      </g>
    </svg>
  );
}
