import { ReactNode } from "react";

const FooterIconLink = ({ href, children }: { href: string; children: ReactNode }) => {
  return (
    <a
      className="flex h-11 w-11 rounded-full border border-whiteB20 p-3 text-white duration-300 hover:bg-white hover:text-blue-700"
      href={href}
      target="_blank"
    >
      {children}
    </a>
  );
};

export default FooterIconLink;
