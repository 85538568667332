import clsx from "clsx";
import { FC } from "react";
import { useLocation, useParams } from "react-router-dom";

import { SideBarMain } from "./SideBarMain";
import { SideBarProfile } from "./SideBarProfile";

interface ISideBarProps {
  className?: string;
}

export const SideBar: FC<ISideBarProps> = ({ className }) => {
  const { pathname } = useLocation();
  const { username } = useParams();

  const isProfile = pathname === "/profile" || username;

  return (
    <div className={clsx("w-sideBar shrink-0 xl:w-sideBarXL", className)}>
      <div
        className={clsx(
          "fixed right-0 flex h-screen w-sideBar flex-col overflow-x-hidden bg-white xl:w-sideBarXL",
          className
        )}
      >
        {isProfile ? <SideBarProfile /> : <SideBarMain />}
      </div>
    </div>
  );
};
