import { IconProps } from "./types";

export function CameraSecondaryIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fill="currentColor"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M430.4 147h-67.5l-40.4-40.8s-.2-.2-.3-.2l-.2-.2c-6-6-14.1-9.8-23.3-9.8h-84c-9.8 0-18.5 4.2-24.6 10.9v.1l-39.5 40h-69C63 147 48 161.6 48 180.2v202.1c0 18.6 15 33.7 33.6 33.7h348.8c18.5 0 33.6-15.1 33.6-33.7V180.2c0-18.6-15.1-33.2-33.6-33.2zM256 365.5c-50.9 0-92.4-41.6-92.4-92.6 0-51.1 41.5-92.6 92.4-92.6 51 0 92.4 41.5 92.4 92.6 0 51-41.4 92.6-92.4 92.6zm168.1-165c-7.7 0-14-6.3-14-14.1s6.3-14.1 14-14.1 14 6.3 14 14.1-6.3 14.1-14 14.1z" />
      <path d="M256 202.9c-38.6 0-69.8 31.3-69.8 70 0 38.6 31.2 70 69.8 70 38.5 0 69.8-31.3 69.8-70s-31.3-70-69.8-70z" />
    </svg>
  );
}
