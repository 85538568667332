import { useRoutes } from "react-router-dom";

import { useAuth } from "context/AuthContext";

import LoggedInRoutes from "./LoggedInRoutes";
import LoggedInWithoutSetUp from "./LoggedInWithoutSetUp";
import LoggedOutRoutes from "./LoggedOutRoutes";
import NotFoundRoute from "./NotFoundRoute";
import SharedProfileLoggedOutRoute from "./SharedProfileLoggedOutRoute";

export default function Routes() {
  const { isLoggedIn, isLoggedInWithoutSetUp } = useAuth();

  const getRoutes = () => {
    if (isLoggedInWithoutSetUp) {
      return LoggedInWithoutSetUp;
    } else if (isLoggedIn) {
      return LoggedInRoutes;
    }

    return LoggedOutRoutes;
  };

  const routes = getRoutes();

  return useRoutes([routes, SharedProfileLoggedOutRoute, NotFoundRoute]);
}
