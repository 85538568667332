import { IconProps } from "./types";

export function WalletIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" {...props}>
      <g fill="currentColor">
        <path d="M18.75 7h-.25V4.75c0-.7-.41-1.29-1-1.57V1.75C17.5.79 16.71 0 15.75 0H2.25C1.01 0 0 1.01 0 2.25c0 .11.02.22.03.33-.01.06-.03.11-.03.17v12c0 .96.79 1.75 1.75 1.75h15c.96 0 1.75-.79 1.75-1.75V12.5h.25c.41 0 .75-.34.75-.75v-4c0-.41-.34-.75-.75-.75zm-5 4a1.25 1.25 0 0 1 0-2.5H18V11h-4.25zM2.25 1.5h13.5c.14 0 .25.11.25.25V3H2.25c-.41 0-.75-.34-.75-.75s.34-.75.75-.75zM17 14.75c0 .14-.11.25-.25.25h-15c-.14 0-.25-.11-.25-.25V4.36c.24.08.49.14.75.14h14.5c.14 0 .25.11.25.25V7h-3.25C12.23 7 11 8.23 11 9.75s1.23 2.75 2.75 2.75H17v2.25z" />
        <path d="M14.77 9c-.41 0-.75.34-.75.75s.34.75.75.75.75-.34.75-.75-.33-.75-.75-.75z" />
      </g>
    </svg>
  );
}
