import { IconProps } from "./types";

export function ProfileEditIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" {...props}>
      <g fill="currentColor">
        <path d="M8.743 9.5c2.62 0 4.75-2.13 4.75-4.75S11.363 0 8.743 0s-4.75 2.13-4.75 4.75 2.13 4.75 4.75 4.75zm0-8c1.79 0 3.25 1.46 3.25 3.25S10.533 8 8.743 8s-3.25-1.46-3.25-3.25 1.46-3.25 3.25-3.25z" />
        <path d="m18.743 9.56-1.09-1.09a1.97 1.97 0 0 0-2.78 0l-2.55 2.55s-.06-.02-.09-.02h-6.48c-2.58 0-4.82 1.75-5.44 4.25l-.29 1.18a.748.748 0 0 0 .73.93c.34 0 .64-.23.73-.57l.29-1.18c.46-1.83 2.1-3.11 3.98-3.11h5.09c-.28.28-.46.63-.54 1.02l-.58 3.09c-.05.24.03.5.21.67.14.14.33.22.53.22.05 0 .09 0 .14-.01l3.09-.58c.39-.07.74-.26 1.02-.54l4.02-4.02c.77-.77.77-2.01 0-2.78l.01-.01zm-1.06 1.72-4.02 4.02c-.07.07-.15.11-.24.13l-2.01.38.38-2.02c.02-.09.06-.17.13-.24l4.02-4.02a.47.47 0 0 1 .33-.14c.12 0 .24.04.33.14l1.09 1.09c.18.18.18.48 0 .66h-.01z" />
      </g>
    </svg>
  );
}
