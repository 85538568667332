export const FlagIcon = ({ code, className }: { code: string; className?: string }) => {
  const codeLower = code.toLowerCase();

  return (
    <img
      loading="lazy"
      width="20"
      src={`https://flagcdn.com/w20/${codeLower}.png`}
      srcSet={`https://flagcdn.com/w20/${codeLower}.png 2x`}
      alt={`${code} flag`}
      className={className}
    />
  );
};
