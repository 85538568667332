import { Link } from "react-router-dom";

import { CopyLink } from "components/CopyLink";
import { ProfileBackground } from "components/ProfileBackground";
import { Typography } from "components/Typography";
import { UserPhoto } from "components/UserPhoto";
import { useAuth } from "context/AuthContext";

export const SideBarMain = () => {
  const {
    user: { fullName, username, profile, userId },
  } = useAuth();

  return (
    <>
      <div className="mb-7 flex w-full flex-col items-center justify-center">
        <ProfileBackground
          className="lg:h-profileBackgroundImage"
          textWrapperClassName="left-7 top-11"
          fullName={fullName}
          username={username}
        />
        <Link className="flex" to="/profile">
          <UserPhoto className="-mt-12" userId={userId} />
        </Link>
      </div>
      <div className="px-4 xl:px-6">
        <Typography className="mb-5 text-center text-grey-0" variant="subheading-md">
          {profile?.bioMessage}
        </Typography>
        <CopyLink className="mb-2.5" username={username} />
      </div>
    </>
  );
};
