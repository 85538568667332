import { IconProps } from "./types";

export function ArrowIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" {...props}>
      <path
        d="M18.682 8.636H1m7.636 7.637L1 8.636 8.636 1"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
