import { FC } from "react";

import { Typography, TypographyVariants } from "components/Typography";
import { numberToLocaleString } from "utils/string";

export interface ITransactionAmountProps {
  className?: string;
  variant?: TypographyVariants;
  amountPrefix?: string;
  amount: number;
}

export const TransactionAmount: FC<ITransactionAmountProps> = ({
  className,
  variant = "h6",
  amountPrefix,
  amount,
}) => {
  return (
    <Typography variant={variant} className={className}>
      {amountPrefix && amountPrefix}
      {numberToLocaleString(amount, { style: "currency", currency: "USD" })}
    </Typography>
  );
};
