import { IconProps } from "./types";

export function SignOutIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <path
        d="m17.78 8.28-5.14-5.13a.5.5 0 0 0-.71 0l-.71.71a.49.49 0 0 0 0 .7L14.67 8H4.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h10.17l-3.45 3.44a.5.5 0 0 0 0 .71l.71.7a.5.5 0 0 0 .71 0l5.14-5.13a.75.75 0 0 0 .22-.53v-.38a.77.77 0 0 0-.22-.53zM7.5 16H2V2h5.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h5.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"
        fill="currentColor"
      />
    </svg>
  );
}
