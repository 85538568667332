import { IconProps } from "./types";

export function SettingsIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g fill="currentColor">
        <path d="M17.99 6.79a.525.525 0 0 1-.35-.3.531.531 0 0 1 .03-.46c.48-.79.35-1.8-.3-2.45l-1.44-1.44c-.65-.65-1.66-.78-2.45-.3a.5.5 0 0 1-.46.03.505.505 0 0 1-.3-.35A2 2 0 0 0 10.77 0H8.74c-.92 0-1.72.63-1.95 1.52-.04.16-.15.28-.3.35-.15.06-.32.05-.46-.03a2.01 2.01 0 0 0-2.45.3L2.14 3.58c-.65.65-.78 1.66-.3 2.45.08.14.1.31.03.46-.06.15-.19.26-.35.3C.62 7.01 0 7.81 0 8.74v2.04c0 .92.63 1.73 1.52 1.95.16.04.28.15.35.3.06.15.05.32-.03.46-.48.79-.35 1.8.3 2.45l1.44 1.44c.65.65 1.66.78 2.45.3a.5.5 0 0 1 .46-.03c.15.06.26.19.3.35a2 2 0 0 0 1.95 1.52h2.03c.92 0 1.72-.63 1.95-1.52.04-.16.15-.28.3-.35.15-.06.32-.05.46.03.79.47 1.8.35 2.45-.3l1.44-1.44c.65-.65.78-1.66.3-2.45a.5.5 0 0 1-.03-.46c.06-.15.19-.26.35-.3.9-.22 1.52-1.02 1.52-1.95V8.74c0-.92-.63-1.73-1.52-1.95zm.02 3.98c0 .23-.16.44-.39.49-.62.16-1.12.59-1.37 1.18s-.2 1.25.13 1.8a.5.5 0 0 1-.08.62l-1.44 1.44c-.17.17-.42.2-.62.08-.55-.33-1.21-.38-1.8-.13-.59.25-1.02.75-1.18 1.37a.51.51 0 0 1-.49.39H8.74c-.23 0-.44-.16-.49-.38A2.027 2.027 0 0 0 6.3 16.1c-.36 0-.71.1-1.03.29a.5.5 0 0 1-.62-.08l-1.44-1.44a.502.502 0 0 1-.08-.62c.33-.55.38-1.21.13-1.8-.25-.59-.74-1.02-1.37-1.18a.502.502 0 0 1-.38-.49V8.74c0-.23.16-.44.39-.49.62-.16 1.12-.59 1.37-1.18s.2-1.25-.13-1.8a.5.5 0 0 1 .08-.62l1.44-1.44c.17-.16.42-.2.62-.08.55.33 1.21.38 1.8.13.59-.25 1.02-.75 1.18-1.37a.51.51 0 0 1 .49-.39h2.03c.23 0 .44.16.49.38.16.62.59 1.12 1.18 1.37.6.25 1.25.2 1.8-.13a.5.5 0 0 1 .62.08l1.44 1.44c.17.17.2.42.08.62-.33.55-.38 1.21-.13 1.8.25.59.74 1.02 1.37 1.18.23.06.38.26.38.49v2.04z" />
        <path d="M9.76 5.01c-2.62 0-4.75 2.13-4.75 4.75s2.13 4.75 4.75 4.75 4.75-2.13 4.75-4.75-2.13-4.75-4.75-4.75zm0 8c-1.79 0-3.25-1.46-3.25-3.25s1.46-3.25 3.25-3.25 3.25 1.46 3.25 3.25-1.46 3.25-3.25 3.25z" />
      </g>
    </svg>
  );
}
