import clsx from "clsx";
import { InputHTMLAttributes, forwardRef } from "react";

import { Loader } from "components/ui/Loader";
import { CameraIcon, PlaneIcon } from "icons";

type InputDefaultProps = InputHTMLAttributes<HTMLInputElement> & {
  isLoading?: boolean;
  onCameraClick?: () => void;
};

export const InputMessage = forwardRef<HTMLInputElement, InputDefaultProps>(
  ({ className, isLoading, onCameraClick, ...inputProps }, ref) => (
    <div className={clsx("relative", className)}>
      <input
        className="w-full rounded-full py-4 pl-5 pr-22 text-subheading-lg outline-none"
        ref={ref}
        {...inputProps}
      />
      {onCameraClick && (
        <button
          className="absolute right-15 top-1/2 -translate-y-1/2 p-2"
          disabled={isLoading}
          onClick={onCameraClick}
          type="button"
        >
          <CameraIcon className="w-6 text-black" />
        </button>
      )}
      <button
        className="absolute right-5 top-1/2 flex -translate-y-1/2 items-center justify-center p-2"
        disabled={isLoading}
        type="submit"
      >
        {!isLoading && <PlaneIcon className="w-6 text-black" />}
        {isLoading && <Loader />}
      </button>
    </div>
  )
);
