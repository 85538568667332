import {
  useContext,
  useState,
  useMemo,
  createContext,
  useCallback,
  PropsWithChildren,
  useEffect,
} from "react";

import { STORAGE_PREFIX } from "configs/app.config";
import { LANGUAGE, LanguageType } from "locales";

interface IThemeContextProps {
  locale: LanguageType;
  setLocale: (value: LanguageType) => void;
}

const initialTheme = {
  locale: JSON.parse(localStorage.getItem(`${STORAGE_PREFIX}lang`) || JSON.stringify(LANGUAGE.En)),
  // eslint-disable-next-line
  setLocale: (value: LanguageType) => {},
};

const ThemeContext = createContext<IThemeContextProps>(initialTheme);
ThemeContext.displayName = "ThemeContext";

const ThemeContextProvider = ({ children }: PropsWithChildren) => {
  const [locale, setStateLocale] = useState<LanguageType>(initialTheme.locale);

  const setLocale = useCallback((locale: LanguageType) => {
    localStorage.setItem(`${STORAGE_PREFIX}lang`, JSON.stringify(locale));
    setStateLocale(locale);
  }, []);

  useEffect(() => {
    const browserLanguage = navigator.language?.split("0")[0]?.split("-")[0];

    if (
      browserLanguage &&
      Object.values(LANGUAGE).includes(browserLanguage as LanguageType) &&
      !localStorage.getItem(`${STORAGE_PREFIX}lang`)
    ) {
      setStateLocale(browserLanguage as LanguageType);
    }
  }, []);

  const value = useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale, setLocale]
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);

export default ThemeContextProvider;
