import { IconProps } from "../types";

export function USAIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
      <path
        fill="#C64252"
        d="M14.786 0c2.808 0 5.437.753 7.695 2.062h-9.81V.148A15.51 15.51 0 0 1 14.785 0zM12.67 16.177h17.28c.03-.389.05-.78.05-1.177s-.02-.788-.05-1.177H12.67v2.354zm0-9.41h14.832a15.108 15.108 0 0 0-1.936-2.351H12.67v2.351zm0 4.709h16.904a14.713 14.713 0 0 0-.79-2.36H12.67v2.36zM14.786 30c2.808 0 5.437-.753 7.695-2.062H7.091A15.302 15.302 0 0 0 14.786 30zM.788 20.883h27.996c.327-.753.59-1.54.788-2.351H0c.199.81.462 1.597.788 2.351zm3.218 4.701h21.56a15.108 15.108 0 0 0 1.936-2.351H2.07a15.108 15.108 0 0 0 1.936 2.351z"
      />
      <path
        fill="#2B497A"
        d="M3.613 5.112a14.97 14.97 0 0 0-2.262 3.524l.162.5h.692l-.56.4.21.65-.552-.406-.56.406.015-.045a14.797 14.797 0 0 0-.738 3.927l-.004.169c-.008.196-.016.391-.016.588 0 .396.02.787.05 1.175H13V0c-.65.09-1.287.22-1.909.39l.17.524h.692l-.56.4.217.656-.559-.406-.559.4.217-.65-.56-.4h.692l.162-.499c-1.182.33-2.304.798-3.349 1.387l-.1.056c-.68.391-1.33.832-1.938 1.32l-.215.175c-.602.5-1.163 1.048-1.679 1.635m-1.866 8.49-.553-.406-.56.4.211-.65-.553-.4h.686l.216-.65.21.65h.693l-.56.4.21.656zm1.954 1.64-.559-.4-.559.4.217-.656-.56-.4h.686l.217-.644.21.65h.692l-.56.4.216.65zm-.216-3.936.216.644-.559-.4-.56.4.217-.65-.559-.4h.686l.216-.65.21.657h.692l-.56.4zm0-3.291.216.655-.559-.405-.559.4.217-.65-.56-.4h.686l.217-.65.21.65h.692l-.56.4zm2.165 5.587-.56-.406-.553.4.211-.65-.56-.4h.698l.205-.65.216.65h.686l-.553.4.21.656zm-.21-3.942.21.65-.56-.406-.553.406.211-.656-.56-.4h.698l.204-.643.217.65H6.1l-.553.4zm0-3.291.21.656-.56-.406-.553.4.211-.65-.56-.4h.698l.205-.65.216.65h.686l-.554.4zm2.158 8.872-.56-.4-.552.4.21-.656-.553-.4h.686l.21-.643.217.65h.686l-.553.4.209.65zm-.21-3.935.21.644-.56-.4-.552.4.21-.65-.553-.4h.686l.21-.65.217.657h.686l-.554.4zm0-3.291.21.655-.56-.405-.552.4.21-.65-.553-.4h.686l.21-.65.217.65h.686l-.554.4zm0-3.286.21.65-.56-.406-.552.406.21-.656-.553-.4h.686l.21-.65.217.656h.686l-.554.4zm2.158 8.873-.553-.406-.559.4.21-.65-.552-.4h.685l.217-.65.21.65h.692l-.56.4.21.656zm-.21-3.942.21.65-.553-.406-.559.406.21-.656-.552-.4h.685l.217-.643.21.65h.692l-.56.4zm0-3.291.21.656-.553-.406-.559.4.21-.65-.552-.4h.685l.217-.65.21.65h.692l-.56.4zm0-3.286.21.65-.553-.4-.559.4.21-.65-.553-.4h.686l.216-.65.21.65h.693l-.56.4zm2.164 12.158-.559-.4-.559.4.217-.656-.56-.4h.692l.21-.643.211.65h.692l-.56.4.216.65zm-.216-3.935.217.644-.56-.4-.558.4.216-.65-.559-.4h.692l.21-.65.21.657h.692l-.56.4zm0-3.291.217.655-.56-.405-.558.4.216-.65-.559-.4h.692l.21-.65.21.65h.692l-.56.4zm0-3.286.217.65-.56-.406-.558.406.216-.656-.559-.4h.692l.21-.65.21.656h.692l-.56.4z"
      />
    </svg>
  );
}
