import { IconProps } from "./types";

export function CirclesIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226 198" {...props}>
      <g fill="none" fillRule="evenodd">
        <ellipse fill="#01DDC3" opacity=".1" cx="154.091" cy="128" rx="71.909" ry="70" />
        <ellipse fill="#F9F9F9" opacity=".06" cx="71.909" cy="70" rx="71.909" ry="70" />
      </g>
    </svg>
  );
}
