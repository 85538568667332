import { IconProps } from "./types";

export function ContactIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" {...props}>
      <g fill="currentColor">
        <path d="M13.75 0h-7C4.13 0 2 2.13 2 4.75V5H.75C.34 5 0 5.34 0 5.75s.34.75.75.75H2V9H.75C.34 9 0 9.34 0 9.75s.34.75.75.75H2V13H.75c-.41 0-.75.34-.75.75s.34.75.75.75H2v.25c0 2.62 2.13 4.75 4.75 4.75h7c2.62 0 4.75-2.13 4.75-4.75v-10C18.5 2.13 16.37 0 13.75 0zM17 14.75c0 1.79-1.46 3.25-3.25 3.25h-7c-1.79 0-3.25-1.46-3.25-3.25v-10c0-1.79 1.46-3.25 3.25-3.25h7C15.54 1.5 17 2.96 17 4.75v10z" />
        <path d="M10.25 10.5c1.79 0 3.25-1.46 3.25-3.25S12.04 4 10.25 4 7 5.46 7 7.25s1.46 3.25 3.25 3.25zm0-5c.96 0 1.75.79 1.75 1.75S11.21 9 10.25 9 8.5 8.21 8.5 7.25s.79-1.75 1.75-1.75zm3.67 7.38A2.754 2.754 0 0 0 11.31 11H9.19c-1.19 0-2.23.76-2.61 1.88l-.54 1.63c-.13.39.08.82.47.95.39.13.82-.08.95-.47L8 13.36c.17-.51.65-.85 1.19-.85h2.12c.54 0 1.02.34 1.19.85l.54 1.63c.11.31.4.51.71.51a.745.745 0 0 0 .71-.99l-.54-1.63z" />
      </g>
    </svg>
  );
}
