import { useEffect } from "react";

import { useAuth } from "context/AuthContext";

const useMouseFlow = () => {
  const {
    user: { userId },
  } = useAuth();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      userId && window._mfq.push(["setVariable", "userId", userId]);
    }
  }, [userId]);
};

export default useMouseFlow;
