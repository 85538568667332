import { IconProps } from "./types";

export function RefreshIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144" {...props}>
      <g stroke="null">
        <path
          fill="none"
          stroke="#2961ff"
          stroke-dasharray="0 0"
          stroke-width="2.222"
          d="M66 130c27.614 0 50-22.386 50-50S93.614 30 66 30 16 52.386 16 80s22.386 50 50 50z"
          vector-effect="non-scaling-stroke"
        />
        <path
          fill="none"
          stroke="#2961ff"
          stroke-dasharray="0 0"
          stroke-opacity=".52"
          stroke-width="1.6"
          d="M66 134c29.823 0 54-24.177 54-54S95.823 26 66 26 12 50.177 12 80s24.177 54 54 54z"
          vector-effect="non-scaling-stroke"
        />
        <path
          fill="none"
          stroke="#2961ff"
          stroke-dasharray="0 0"
          stroke-opacity=".08"
          stroke-width="2"
          d="M66 138c32.033 0 58-25.967 58-58S98.033 22 66 22 8 47.967 8 80s25.967 58 58 58z"
          vector-effect="non-scaling-stroke"
        />
        <path
          fill="#313381"
          fill-rule="evenodd"
          d="M12 32a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm-7 96a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm98-106a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm32 36a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM18 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm99 37a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-14 100a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-81 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM4 50a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
          vector-effect="non-scaling-stroke"
        />
        <path
          fill="#313381"
          d="M96.189 72.875a3.839 3.839 0 0 0-5.43 0l-1.67 1.67A23.061 23.061 0 0 0 66.594 56.39a22.752 22.752 0 0 0-13.837 4.71 3.839 3.839 0 0 0 4.634 6.12 15.148 15.148 0 0 1 9.203-3.15 15.332 15.332 0 0 1 14.51 10.545l-2.54-2.025a3.837 3.837 0 1 0-4.785 6l9.622 7.68a3.834 3.834 0 0 0 5.107-.285l7.68-7.68a3.839 3.839 0 0 0 0-5.43zM75.797 91.64a15.148 15.148 0 0 1-9.203 3.15 15.332 15.332 0 0 1-14.51-10.545l2.54 2.025a3.837 3.837 0 1 0 4.785-6l-9.622-7.68a3.844 3.844 0 0 0-5.107.285L37 80.555a3.84 3.84 0 0 0 5.43 5.43l1.67-1.67a23.061 23.061 0 0 0 22.494 18.155 22.752 22.752 0 0 0 13.837-4.71 3.839 3.839 0 0 0-4.634-6.12z"
          vector-effect="non-scaling-stroke"
        />
      </g>
    </svg>
  );
}
