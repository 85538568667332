import { AxiosRequestConfig } from "axios";

import instance, { unauthorizedApiInstance } from "./instance";

interface UserCredentials {
  email: string;
  password: string;
}

export interface IRegisterPayload extends UserCredentials {
  purchase?: {
    purchaseAmount: number;
    purchaseUsername: string;
    purchaseRequest: boolean;
  };
}

export const fetchRegister = ({
  payload,
  utmParams,
}: {
  payload: IRegisterPayload;
  utmParams: any; // eslint-disable-line
}) => {
  const { email, password, purchase } = payload;

  let paramsToSend = {};
  if (utmParams !== null) {
    paramsToSend = { params: utmParams };
  }

  unauthorizedApiInstance.post(
    "/app/user/create/",
    {
      email,
      password,
      purchase,
    },
    paramsToSend
  );
};

export const fetchVerifyEmail = (
  { email, otp }: { email: string; otp: string },
  options?: AxiosRequestConfig<{ email: string; otp: string }>
) =>
  unauthorizedApiInstance.post(
    "/app/user/otp/email/",
    {
      email,
      otp,
    },
    options
  );

export const fetchLogin = ({ email, password }: UserCredentials) =>
  unauthorizedApiInstance.post("app/user/login/", {
    email,
    password,
  });

export const fetchLogout = ({ userId, token }: { userId: string; token: string }) =>
  instance.post(`app/user/${userId}/logout/`, {
    token,
  });

export const fetchResendEmail = (
  {
    email,
  }: {
    email: string;
  },
  options?: AxiosRequestConfig<{ email: string }>
) =>
  unauthorizedApiInstance.post(
    "/app/user/otp/email/resend/",
    {
      email,
    },
    options
  );

export const fetchForgotPassword = ({ email }: { email: string }) =>
  unauthorizedApiInstance.post("app/user/forgot-password", {
    email,
  });

export const fetchResetPassword = ({
  email,
  saltcode,
  password_1,
  password_2,
}: {
  email: string;
  saltcode: string;
  password_1: string;
  password_2: string;
}) =>
  unauthorizedApiInstance.patch("app/user/reset-password", {
    email,
    saltcode,
    password_1,
    password_2,
  });

export const fetchChangePassword = ({
  userId,
  current_password,
  password_1,
  password_2,
}: {
  userId: string;
  current_password: string;
  password_1: string;
  password_2: string;
}) =>
  instance.patch(`app/user/${userId}/account/change-password`, {
    current_password,
    password_1,
    password_2,
  });
