import { IconProps } from "../types";

export function SpainIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
      <path
        fill="#F44F5A"
        d="M27.977 7.5H2.023C4.618 3.021 9.45 0 15 0s10.382 3.021 12.977 7.5zM15 30c5.549 0 10.382-3.021 12.977-7.5H2.023C4.618 26.979 9.451 30 15 30z"
      />
      <path
        fill="#FEDE00"
        d="M30 15c0-2.735-.744-5.292-2.023-7.5H2.023a14.914 14.914 0 0 0 0 15h25.954A14.914 14.914 0 0 0 30 15z"
      />
    </svg>
  );
}
