import clsx from "clsx";
import { useEffect, useState } from "react";

import { Typography } from "components/Typography";
import { MEDIA_TYPE } from "types/transaction";

export const UploadFile = ({
  title,
  className,
  mediaType,
  value,
  onChange,
}: {
  title: string;
  className?: string;
  mediaType: MEDIA_TYPE;
  value: File | null;
  onChange: (file: File) => void;
}) => {
  const [preview, setPreview] = useState<string>("");

  const fileAccept =
    mediaType === MEDIA_TYPE.PHOTO ? "image/jpeg,image/png" : "video/mp4,video/quicktime,video/mov";

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const preview = URL.createObjectURL(file);

      setPreview(preview);
    }

    if (file) onChange(file);
  };

  useEffect(() => {
    if (!value) {
      setPreview("");
    }
  }, [value]);

  return (
    <label
      className={clsx(
        "flex h-52 w-52 items-center justify-center rounded-2xl bg-grey-700 lg:h-52 lg:w-52",
        className
      )}
    >
      {!value && <Typography variant="body">{title}</Typography>}

      {value && mediaType === MEDIA_TYPE.PHOTO && (
        <img src={preview} alt="avatar" className="object-cover" />
      )}

      {value && mediaType === MEDIA_TYPE.VIDEO && (
        <video id="videoPlayer" controls src={preview} className="h-full object-cover" />
      )}
      <input type="file" className="hidden" onChange={handleFileSelect} accept={fileAccept} />
    </label>
  );
};
