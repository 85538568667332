import clsx from "clsx";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Typography } from "components/Typography";
import { Button } from "components/ui";

// TODO rework

interface IConfirmDialogProps {
  title: string;
  description?: string;
  className?: string;
  isLoading?: boolean;
  cancelTitleId?: string;
  submitTitleId?: string;
  onCancel?: () => void;
  onSubmit: () => void;
}

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  title,
  description,
  className,
  isLoading,
  cancelTitleId = "buttons.no",
  submitTitleId = "buttons.yes",
  onCancel,
  onSubmit,
}) => {
  return (
    <div className={clsx("flex w-full flex-col", className)}>
      <Typography className="mb-2 text-center" variant="h6">
        <FormattedMessage id={title} />
      </Typography>
      {description && (
        <Typography className="text-center" variant="subheading-md">
          <FormattedMessage id={description} />
        </Typography>
      )}
      <div className="mt-6 flex w-full gap-2">
        {onCancel && (
          <Button
            variant="outlined"
            size="medium"
            onClick={onCancel}
            disabled={isLoading}
            className="w-full"
          >
            <FormattedMessage id={cancelTitleId} />
          </Button>
        )}
        <Button size="medium" isLoading={isLoading} onClick={onSubmit} className="w-full">
          <FormattedMessage id={submitTitleId} />
        </Button>
      </div>
    </div>
  );
};
