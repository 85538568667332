import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";

import AuthContextProvider from "context/AuthContext";
import PhotoContextProvider from "context/PhotoContext";
import { QueryProvider } from "context/queryProvider";
import { SnackBarContextProvider } from "context/SnackbarContext";
import ThemeContextProvider from "context/ThemeContext";
import { UTMProvider } from "context/UTMContext";

import App from "./App";

import "./index.css";
import "yet-another-react-lightbox/styles.css";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  ReactGA.initialize("G-HCNPH0TX2B");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <SnackBarContextProvider>
        <BrowserRouter>
          <QueryProvider>
            <AuthContextProvider>
              <PhotoContextProvider>
                <UTMProvider>
                  <App />
                </UTMProvider>
              </PhotoContextProvider>
            </AuthContextProvider>
          </QueryProvider>
        </BrowserRouter>
      </SnackBarContextProvider>
    </ThemeContextProvider>
  </React.StrictMode>
);
