import React, {
  createContext,
  useState,
  PropsWithChildren,
  useMemo,
  useContext,
  useCallback,
} from "react";

interface IPhotoContextValue {
  photoKey: number | null;
  updatePhotoKey: () => void;
}

const PhotoContext = createContext<IPhotoContextValue>({
  photoKey: null,
  updatePhotoKey: () => {},
});

PhotoContext.displayName = "PhotoContext";

const PhotoContextProvider = ({ children }: PropsWithChildren<object>) => {
  const [photoKey, setPhotoKey] = useState<number | null>(Date.now());

  const updatePhotoKey = useCallback(() => {
    setPhotoKey(Date.now());
  }, []);

  const value = useMemo(
    () => ({
      photoKey,
      updatePhotoKey,
    }),
    [photoKey, updatePhotoKey]
  );

  return <PhotoContext.Provider value={value}>{children}</PhotoContext.Provider>;
};

export const usePhoto = (): IPhotoContextValue => useContext(PhotoContext);

export default PhotoContextProvider;
