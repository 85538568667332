import { useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import {
  createContext,
  useContext,
  useState,
  useMemo,
  PropsWithChildren,
  useCallback,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

import { fetchLogout } from "api/authorizationAPI";
import instance from "api/instance";
import { STORAGE_PREFIX } from "configs/app.config";
import { parseUser } from "services/userServices";
import { IUser } from "types/user";

const initialUser = {
  user: {
    userId: "",
    email: "",
    accountStatus: "",
    emailStatus: "",
  },
  authToken: null,
};

const initialValues = JSON.parse(
  Cookies.get(`${STORAGE_PREFIX}user`) || JSON.stringify(initialUser)
);

const AuthContext = createContext(initialValues);
AuthContext.displayName = "AuthContext";

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<IUser>(initialValues.user);
  const [authToken, setAuthToken] = useState(initialValues.authToken);
  const [isLoggedInWithoutSetUp, setIsLoggedInWithoutSetUp] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    const { username, country, fullName } = user;
    if ((!username || !country || !fullName) && authToken) {
      setIsLoggedInWithoutSetUp(true);
      navigate("/set-up");
    } else {
      setIsLoggedInWithoutSetUp(false);
    }
  }, [user, authToken]); // eslint-disable-line

  const setAuthData = useCallback(
    //response data
    //eslint-disable-next-line
    (data: any) => {
      const authToken = data.auth_token;
      const userData = parseUser(data);

      setUser(userData);
      setAuthToken(authToken);

      instance.defaults.headers.common.authtoken = authToken;

      Cookies.set(
        `${STORAGE_PREFIX}user`,
        JSON.stringify({
          user: userData,
          authToken,
        }),
        { sameSite: "strict" }
      );
    },
    [] // eslint-disable-line
  );

  const clearAuthData = useCallback(
    () => {
      const { authToken, user } = initialUser;

      setUser(user);
      setAuthToken(authToken);

      Cookies.set(
        `${STORAGE_PREFIX}user`,
        JSON.stringify({
          user: user,
          authToken,
        }),
        { sameSite: "strict" }
      );

      navigate("/");

      queryClient.removeQueries();
    },
    [] // eslint-disable-line
  );

  const handleLogout = useCallback(async () => {
    try {
      setIsLogoutLoading(true);

      await fetchLogout({ userId: user.userId, token: authToken });
    } finally {
      clearAuthData();
      setIsLogoutLoading(false);
    }
  }, [authToken, user.userId]); // eslint-disable-line

  const value = useMemo(
    () => ({
      user,
      authToken,
      isLogoutLoading,
      isLoggedInWithoutSetUp,
      isLoggedIn: !!authToken,
      logout: handleLogout,
      setAuthData,
      clearAuthData,
    }),
    [
      user,
      authToken,
      isLoggedInWithoutSetUp,
      isLogoutLoading,
      handleLogout,
      setAuthData,
      clearAuthData,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthContextProvider;
