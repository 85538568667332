import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { FocusEvent, KeyboardEvent, forwardRef, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useOnClickOutside } from "usehooks-ts";

import { Input, Loader } from "components/ui";
import { ChevronIcon } from "icons";
import { OptionType } from "types/common.types";

import AutocompleteVirtualizedList from "../AutocompleteVirtualizedList";

interface IAutocompleteProps {
  className?: string;
  options: OptionType[];
  placeholder: string;
  error?: string;
  isErrorWithoutMessage?: boolean;
  value: OptionType | null;
  onChange: (...event: any[]) => void; //eslint-disable-line
  onBlur?: (...event: any[]) => void; //eslint-disable-line
  onFocus?: (...event: any[]) => void; //eslint-disable-line
  // onChange, onBlur, onFocus from react-hook-form may vary
  disabled?: boolean;
  isLoading?: boolean;
  isCountryFlag?: boolean;
}

export const Autocomplete = forwardRef<HTMLInputElement, IAutocompleteProps>(
  (
    {
      className,
      options,
      placeholder,
      error,
      isErrorWithoutMessage,
      value,
      onChange,
      onBlur,
      onFocus,
      disabled,
      isLoading,
      isCountryFlag = false,
    },
    ref
  ) => {
    const [query, setQuery] = useState<string>(value?.name || "");

    useEffect(() => {
      setQuery(value?.name || "");
    }, [value?.name]);

    const [debouncedQuery, setDebouncedQuery] = useState<string>("");

    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
      onBlur && onBlur(e);
      setIsFocused(false);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
      onFocus && onFocus(e);
      setIsFocused(true);
    };

    useEffect(() => {
      const debounceTimeout = setTimeout(() => {
        setDebouncedQuery(query);
      }, 300);

      return () => clearTimeout(debounceTimeout);
    }, [query]);

    const filteredOptions =
      debouncedQuery === ""
        ? options
        : options.filter((item) =>
            item.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(debouncedQuery.toLowerCase().replace(/\s+/g, ""))
          );

    const comboboxRef = useRef(null);
    useOnClickOutside(
      comboboxRef,
      () => {
        if (query !== value?.name) {
          onChange(null);
          setQuery("");
        }
      },
      "mouseup"
    );

    const isNotFound = filteredOptions.length === 0 && query !== "";

    const handleSelect = (item: OptionType) => {
      onChange(item);
      setQuery(item.name);
    };

    const clearQueryByCondition = () => {
      if (isNotFound && !value) {
        setQuery("");
      }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        clearQueryByCondition();
      }
    };

    const handleButtonClick = (isSelected: boolean) => {
      if (isSelected) {
        clearQueryByCondition();
      }
    };

    return (
      <Combobox
        as="div"
        className={clsx("relative w-full", className)}
        value={value}
        ref={comboboxRef}
        onChange={handleSelect}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {({ open }) => (
          <div className="relative">
            <Combobox.Button className="relative" as="div" onClick={() => handleButtonClick(open)}>
              <Input
                ref={ref}
                value={query}
                error={isFocused ? "" : error}
                isErrorWithoutMessage={isErrorWithoutMessage}
                placeholder={placeholder}
                isSelected={open}
                autoComplete="off"
                onChange={(event) => setQuery(event.target.value)}
                onKeyDown={handleKeyDown}
                customInput={Combobox.Input}
              />
              <ChevronIcon
                className={clsx(
                  "absolute inset-y-1/2 bottom-0 right-4 h-2 w-3 -translate-y-1/2 text-blue-200 duration-300",
                  {
                    "rotate-180": open,
                  }
                )}
              />
            </Combobox.Button>
            {isLoading && <Loader absolute />}

            <Combobox.Options>
              <div className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {isNotFound ? (
                  <div className="relative cursor-default select-none px-4 py-2">
                    <FormattedMessage id="autocomplete.nothingFound" />
                  </div>
                ) : (
                  <AutocompleteVirtualizedList
                    options={filteredOptions ?? []}
                    isCountryFlag={isCountryFlag}
                  />
                )}
              </div>
            </Combobox.Options>
          </div>
        )}
      </Combobox>
    );
  }
);
