import clsx from "clsx";
import { formatDistance, parseISO } from "date-fns";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import CardContainer from "components/CardContainer";
import { TransactionAmount } from "components/TransactionAmount";
import { Typography } from "components/Typography";
import Chip from "components/ui/Chip";
import { UserPhoto } from "components/UserPhoto";
import { TRANSACTION_STATUS } from "constants/transaction";
import { useTheme } from "context/ThemeContext";
import { DATE_FNS_LOCALE } from "locales";
import { ITransaction, TRANSACTION_TYPE } from "types/transaction";

import UserTransactionCardRequestedStatus from "./UserTransactionCardRequestedStatus";

export interface ITransactionCardProps {
  className?: string;
  data: ITransaction;
  isSmallSize?: boolean;
}

export const UserTransactionCard: FC<ITransactionCardProps> = ({
  className,
  data,
  isSmallSize,
}) => {
  const { locale } = useTheme();
  const navigate = useNavigate();

  const {
    senderUserId,
    senderUsername,
    receiverUserId,
    receiverUsername,
    createdAt,
    paymentStatus,
    paymentId,
    transactionType,
    isActionRequired,
  } = data;

  const isSenderType = transactionType === TRANSACTION_TYPE.SENDER;

  const username = isSenderType ? receiverUsername : senderUsername;
  const userId = isSenderType ? receiverUserId : senderUserId;

  const handleNavigateToDetails = () => {
    navigate(`/transactions/transaction-details?id=${paymentId}`);
  };

  return (
    <CardContainer
      className={clsx(className, "flex-col !items-end")}
      onClick={handleNavigateToDetails}
    >
      <div className="flex h-full w-full items-center">
        <UserPhoto className="mr-3.5" userId={userId} size="small" />
        <div className="overflow-hidden">
          <Typography className="truncate font-semibold text-blue-0" variant="body-lg">
            {username}
          </Typography>
          <Typography className="text-grey-0" variant="caption">
            {formatDistance(parseISO(createdAt), new Date(), {
              addSuffix: true,
              locale: DATE_FNS_LOCALE[locale],
            })}
          </Typography>
        </div>
        <div className="ml-auto flex flex-col items-end">
          {paymentStatus === TRANSACTION_STATUS.AWAITING && (
            <Chip color="orange" className="shrink-0 whitespace-nowrap">
              <FormattedMessage id="awaitingStatusShort" />
            </Chip>
          )}
          {paymentStatus === TRANSACTION_STATUS.NEW && (
            <Chip
              color={isActionRequired ? "orange" : "grey"}
              className="shrink-0 whitespace-nowrap"
            >
              <FormattedMessage
                id={isActionRequired ? "actionRequiredStatus" : "pendingReplyStatus"}
              />
            </Chip>
          )}
          <TransactionAmount
            amount={data.amount}
            variant="h6"
            className={isSenderType ? "text-red" : "text-green"}
            amountPrefix={isSenderType ? "-" : "+"}
          />
          {!isSmallSize && <UserTransactionCardRequestedStatus transaction={data} />}
        </div>
      </div>
      {isSmallSize && <UserTransactionCardRequestedStatus transaction={data} />}
    </CardContainer>
  );
};
