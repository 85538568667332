import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { STORAGE_PREFIX } from "configs/app.config";

interface UTMParams {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
}

const UTMContext = createContext<{
  utmParams: UTMParams | null;
  resetUTMParams: () => void;
}>({
  utmParams: null,
  resetUTMParams: () => {},
});

export const useUTM = () => {
  return useContext(UTMContext);
};

const getUTMParams = (search: string): UTMParams => {
  const params = new URLSearchParams(search);
  return {
    utm_source: params.get("utm_source") || "",
    utm_medium: params.get("utm_medium") || "",
    utm_campaign: params.get("utm_campaign") || "",
    utm_term: params.get("utm_term") || "",
    utm_content: params.get("utm_content") || "",
  };
};

interface UTMProviderProps {
  children: ReactNode;
}
export const UTMProvider: React.FC<UTMProviderProps> = ({ children }) => {
  const location = useLocation();
  const [utmParams, setUTMParams] = useState<UTMParams | null>(() => {
    const storedUTMParams = localStorage.getItem(`${STORAGE_PREFIX}utmParams`);
    if (storedUTMParams) {
      return JSON.parse(storedUTMParams);
    } else {
      const paramsFromUrl = getUTMParams(location.search);
      if (Object.values(paramsFromUrl).every((param) => param === "")) {
        return null;
      } else {
        return paramsFromUrl;
      }
    }
  });

  useEffect(() => {
    const paramsFromUrl = getUTMParams(location.search);
    const storedUTMParams = localStorage.getItem(`${STORAGE_PREFIX}utmParams`);

    if (Object.values(paramsFromUrl).every((param) => param === "")) {
      // No UTM params in URL
      if (storedUTMParams) {
        // Use existing params from localStorage if available
        setUTMParams(JSON.parse(storedUTMParams));
      } else {
        setUTMParams(null);
        localStorage.removeItem(`${STORAGE_PREFIX}utmParams`);
      }
    } else {
      // UTM params found in URL
      setUTMParams(paramsFromUrl);
      localStorage.setItem(`${STORAGE_PREFIX}utmParams`, JSON.stringify(paramsFromUrl));
    }
  }, [location.search]);

  const resetUTMParams = () => {
    setUTMParams(null);
    localStorage.removeItem(`${STORAGE_PREFIX}utmParams`);
  };

  return (
    <UTMContext.Provider value={{ utmParams, resetUTMParams }}>{children}</UTMContext.Provider>
  );
};
