import { FC, useEffect, useRef } from "react";
import { useIntersectionObserver } from "usehooks-ts";

const ObserverDataLoader: FC<{ onShow: () => void; rootMargin: string }> = ({
  onShow,
  rootMargin,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    rootMargin,
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      onShow();
    }
  }, [entry, onShow]);

  return <div ref={ref} />;
};

ObserverDataLoader.displayName = "ObserverDataLoader";

export default ObserverDataLoader;
