import clsx from "clsx";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { ClockMoneyIcon, HomeIcon, SettingsIcon, UserIcon, AirplaneIcon } from "icons";

export const NAVBAR_MOBILE_HIDDEN_PATHS = ["/payment"];

const LINKS = [
  {
    href: "/",
    icon: HomeIcon,
  },
  {
    href: "/transactions",
    icon: ClockMoneyIcon,
  },
  {
    href: "/payment",
    icon: AirplaneIcon,
    className: "rounded-full bg-blue-600",
    iconClassName: "text-white relative -left-0.5",
  },
  {
    href: "/settings",
    icon: SettingsIcon,
  },
  {
    href: "/profile",
    icon: UserIcon,
  },
];

interface INavBarMobileProps {
  className?: string;
}

export const NavBarMobile: FC<INavBarMobileProps> = ({ className }) => {
  const { pathname } = useLocation();

  if (NAVBAR_MOBILE_HIDDEN_PATHS.includes(pathname)) return null;

  return (
    <div
      className={clsx(
        "fixed inset-x-0 bottom-0 z-50 flex items-center justify-between bg-white px-6 py-2.5",
        className
      )}
    >
      {LINKS.map(({ href, icon: Icon, className, iconClassName }) => {
        const isActivePath = pathname === href;

        return (
          <NavLink
            className={clsx("flex h-12 w-12 items-center justify-center", className)}
            to={href}
            key={href}
          >
            <Icon
              className={clsx(
                "h-5 ",
                {
                  "text-blue-700": isActivePath,
                  "text-grey-0": !isActivePath,
                },
                iconClassName
              )}
            />
          </NavLink>
        );
      })}
    </div>
  );
};
