import React, { useState, useContext, useEffect, useCallback, PropsWithChildren } from "react";

import { LocalizedError } from "types/errorTypes";

const DISPLAY_DURATION = 5000;

type SnackBarValueType = {
  message: string;
  type?: "error" | "success" | "warning";
};

const SnackbarContext = React.createContext({
  isShow: false,
  type: "",
  message: "",
  // eslint-disable-next-line
  openNotification: (value: SnackBarValueType) => {},
  onClose: () => {},
  // eslint-disable-next-line
  openErrorNotification: (error: any) => {},
  // eslint-disable-next-line
  openWarningNotification: (error: any) => {},
  // eslint-disable-next-line
  openSuccessNotification: (message: string) => {},
});

export default SnackbarContext;

const INITIAL_VALUE = {
  message: "",
};

export const SnackBarContextProvider = ({ children }: PropsWithChildren) => {
  const [isShow, setIsShow] = useState(false);

  const [value, setValue] = useState<SnackBarValueType>(INITIAL_VALUE);
  const { type = "", message } = value;

  const displayHandler = ({ type = "error", message = "" }: SnackBarValueType) => {
    setValue({
      type,
      message,
    });

    setIsShow(true);
  };

  const openErrorNotification = (error: LocalizedError) => {
    displayHandler({
      type: "error",
      message: error.response?.data?.detail || error.message,
    });
  };

  const openWarningNotification = (error: LocalizedError) => {
    displayHandler({
      type: "warning",
      message: error.response?.data?.detail || error.message,
    });
  };

  const openSuccessNotification = (message: string) => {
    displayHandler({
      type: "success",
      message: message,
    });
  };

  const clearHandler = useCallback(() => {
    setValue(INITIAL_VALUE);
    setIsShow(false);
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      clearHandler();
    }, DISPLAY_DURATION);

    if (!isShow) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isShow, clearHandler]);

  return (
    <SnackbarContext.Provider
      value={{
        isShow,
        type,
        message,
        openNotification: displayHandler,
        onClose: clearHandler,
        openErrorNotification,
        openWarningNotification,
        openSuccessNotification,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackBarContext = () => useContext(SnackbarContext);
