import { IconProps } from "./types";

export function UserIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <g fill="currentColor">
        <path d="M9.75 0C4.37 0 0 4.37 0 9.75s4.37 9.75 9.75 9.75 9.75-4.37 9.75-9.75S15.13 0 9.75 0zM5.44 16.77a5.266 5.266 0 0 1 4.31-2.27c1.72 0 3.33.85 4.32 2.27A8.156 8.156 0 0 1 9.75 18c-1.59 0-3.05-.45-4.31-1.23zm9.82-.9A6.73 6.73 0 0 0 9.75 13c-2.2 0-4.25 1.09-5.51 2.87A8.206 8.206 0 0 1 1.5 9.75C1.5 5.2 5.2 1.5 9.75 1.5S18 5.2 18 9.75c0 2.43-1.06 4.61-2.74 6.12z" />
        <path d="M9.75 4C7.68 4 6 5.68 6 7.75c0 2.07 1.68 3.75 3.75 3.75 2.07 0 3.75-1.68 3.75-3.75C13.5 5.68 11.82 4 9.75 4zm0 6C8.51 10 7.5 8.99 7.5 7.75S8.51 5.5 9.75 5.5 12 6.51 12 7.75 10.99 10 9.75 10z" />
      </g>
    </svg>
  );
}
