import { IconProps } from "./types";

export function PlayIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <g transform="translate(128 278)">
        <path
          id="Fill-124"
          d="M-95.9-222c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9S-72-259.1-72-245.9-82.7-222-95.9-222zm0-45.2c-11.7 0-21.3 9.6-21.3 21.3 0 11.7 9.6 21.3 21.3 21.3s21.3-9.6 21.3-21.3c0-11.8-9.6-21.3-21.3-21.3z"
          fill="white"
        />
        <path
          id="Fill-125"
          d="M-103-233.6v-24.7l21.2 12.4-21.2 12.3zm2.8-19.8v14.9l12.7-7.4-12.7-7.5z"
          fill="white"
        />
      </g>
    </svg>
  );
}
