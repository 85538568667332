import { TRANSACTION_STATUS, WITHDRAWAL_TRANSACTION_STATUS } from "constants/transaction";

export interface ITransactions {
  totalPages: number;
  transactions: ITransaction[];
}

export enum TransactionStatus {
  PENDING = "pending",
  COMPLETE = "complete",
}

export enum MEDIA_TYPE {
  VIDEO = "Video",
  PHOTO = "Photo",
}

export enum TRANSACTION_TYPE {
  CARD = "card",
  RECEIVER = "receiver",
  SENDER = "sender",
  WITHDRAWAL = "withdrawal",
}

export enum ACTIVITY_TYPE {
  DEPOSIT = "deposit",
  REQUEST = "request",
  SEND = "send",
  WITHDRAWAL = "withdrawal",
}

export interface ITransaction {
  amount: number;
  signedAmount: number;
  createdAt: string;
  paymentId: string;
  paymentMethodId: string;
  messages: ITransactionMessage[];
  paymentStatus: TRANSACTION_STATUS;
  status: WITHDRAWAL_TRANSACTION_STATUS;
  transactionType: TRANSACTION_TYPE;
  type: ACTIVITY_TYPE;
  receiverPhoto: string;
  receiverUserId: string;
  receiverUsername: string;
  senderPhoto: string;
  senderUserId: string;
  senderUsername: string;
  mediaType: MEDIA_TYPE;
  description: string;
  isActionRequired: boolean;
}

export interface ITransactionMessage {
  createdAt: string;
  message: string;
  messageReceiverUserId: string;
  messageSenderUserId: string;
  messageId?: string;
  messageMediaId?: string;
  paymentTriggered: boolean;
}
