import clsx from "clsx";
import { FC } from "react";

export type LoaderSizeType = "small" | "medium" | "large";

interface ILoaderProps {
  className?: string;
  size?: LoaderSizeType;
  absolute?: boolean;
  color?: "blue" | "white";
}

export const Loader: FC<ILoaderProps> = ({
  className,
  absolute,
  size = "small",
  color = "blue",
}) => (
  <div
    className={clsx(
      {
        "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2": absolute,
        "flex items-center justify-center": !absolute,
      },
      className
    )}
  >
    <div
      className={clsx("spinner", {
        "h-10 w-10": size === "small",
        "h-15 w-15": size === "medium",
        "h-20 w-20": size === "large",
      })}
    >
      {new Array(4).fill("").map((_, i) => (
        <div
          key={i}
          className={clsx("border-x-transparent border-b-transparent ", {
            "h-6 w-6 border-4": size === "small",
            "h-10 w-10 border-4": size === "medium",
            "h-16 w-16 border-[7px]": size === "large",
            "border-t-blue-600": color === "blue",
            "border-t-white": color === "white",
          })}
        />
      ))}
    </div>
  </div>
);
