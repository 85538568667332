import { FormattedMessage } from "react-intl";

import { TransactionTabs } from "components/TransactionTabs";
import { Typography } from "components/Typography";
import { Button } from "components/ui";

export const SideBarProfile = () => {
  return (
    <div className="px-4 py-10 xl:px-6">
      <Typography className="mb-4" variant="h5">
        <FormattedMessage id="sidebar.profile.title" />
      </Typography>
      <TransactionTabs
        className="mb-4 !w-full"
        loaderClassName="justify-center"
        loaderSize="medium"
        isSmallCards
      >
        <Button component="Link" to="/transactions">
          <FormattedMessage id="buttons.goToTransactions" />
        </Button>
      </TransactionTabs>
    </div>
  );
};
