import { IconProps } from "./types";

export function ClockMoneyIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <g fill="currentColor">
        <path d="M7.924 16.109A7.389 7.389 0 0 1 1.346 8.75c0-4.083 3.32-7.404 7.404-7.404a7.414 7.414 0 0 1 6.982 4.936c.126.35.512.539.862.413a.685.685 0 0 0 .412-.862A8.77 8.77 0 0 0 8.75 0C3.922 0 0 3.922 0 8.75a8.739 8.739 0 0 0 7.78 8.696h.073a.663.663 0 0 0 .664-.601.674.674 0 0 0-.593-.745v.009z" />
        <path d="M13.686 8.077c-2.136 0-3.814 1.086-3.814 2.468v4.487c0 1.382 1.678 2.468 3.814 2.468s3.814-1.086 3.814-2.468v-4.487c0-1.382-1.678-2.468-3.814-2.468zm0 1.346c1.508 0 2.468.664 2.468 1.122 0 .458-.96 1.122-2.468 1.122s-2.468-.664-2.468-1.122c0-.458.96-1.122 2.468-1.122zm0 6.73c-1.508 0-2.468-.663-2.468-1.12v-2.604c.664.36 1.517.584 2.468.584.951 0 1.804-.225 2.468-.584v2.603c0 .458-.96 1.122-2.468 1.122zM8.75 2.692a.678.678 0 0 0-.673.673v4.272c0 .43-.233.817-.62 1.005L4.865 9.944a.684.684 0 0 0-.305.906.684.684 0 0 0 .601.377.686.686 0 0 0 .296-.072L8.05 9.854a2.45 2.45 0 0 0 1.364-2.208v-4.28a.678.678 0 0 0-.673-.674h.009z" />
      </g>
    </svg>
  );
}
