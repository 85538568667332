import { IconProps } from "./types";

export function SearchIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7.622 13.067a5.444 5.444 0 1 0 0-10.89 5.444 5.444 0 0 0 0 10.89zM7.622 0a7.622 7.622 0 0 1 6.105 12.187l2.287 2.287a1.089 1.089 0 0 1-1.54 1.54l-2.287-2.287A7.622 7.622 0 1 1 7.622 0z"
      />
    </svg>
  );
}
