import clsx from "clsx";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";

import { ConfirmDialog } from "components/ConfirmDialog";
import { Typography } from "components/Typography";
import { Popup } from "components/ui";
import { useAuth } from "context/AuthContext";
import {
  AirplaneIcon,
  BankIcon,
  ClockMoneyIcon,
  HomeIcon,
  InfoIcon,
  LogoIcon,
  SettingsIcon,
  SignOutIcon,
  UserIcon,
} from "icons";

const TABS = [
  {
    href: "/",
    text: "tabBar.link.home",
    icon: HomeIcon,
  },
  {
    href: "/transactions",
    text: "tabBar.link.activity",
    icon: ClockMoneyIcon,
  },
  {
    href: "/withdraw-funds",
    text: "tabBar.link.withdrawals",
    icon: BankIcon,
  },
  {
    href: "/settings",
    text: "tabBar.link.settings",
    icon: SettingsIcon,
  },
  {
    href: "/profile",
    text: "tabBar.link.myProfile",
    icon: UserIcon,
  },
  {
    href: "https://enviago.zendesk.com/hc/en-us",
    text: "tabBar.link.support",
    icon: InfoIcon,
    target: "_blank",
  },
];

interface ITabBarProps {
  className?: string;
}

export const TabBar: FC<ITabBarProps> = ({ className }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const { pathname } = useLocation();

  const { logout, isLogoutLoading } = useAuth();

  const isPaymentTabActive = pathname.includes("/payment");

  return (
    <div className={clsx("w-tabBar shrink-0", className)}>
      <div className="fixed left-0 flex h-screen w-tabBar  flex-col bg-white py-9 text-grey-0">
        <LogoIcon className="mb-14 h-10 text-blue-700" />
        {TABS.map(({ href, text, icon: Icon, target }) => (
          <NavLink
            className={({ isActive }) =>
              clsx(
                "flex items-center px-10 py-5 duration-300 hover:bg-grey-900 hover:text-blue-700",
                {
                  "bg-grey-900 text-blue-700": isActive,
                }
              )
            }
            to={href}
            key={href}
            target={target}
          >
            <Icon className="mr-3.5 h-5 shrink-0" />
            <Typography variant="base">
              <FormattedMessage id={text} />
            </Typography>
          </NavLink>
        ))}

        <div className="mt-5 px-5">
          <NavLink
            className={clsx("group flex items-center rounded-full p-1 duration-300", {
              "bg-blue-700 text-white": isPaymentTabActive,
              " bg-grey-900 text-black hover:bg-blue-700 hover:text-white": !isPaymentTabActive,
            })}
            to="/payment"
          >
            <div
              className={clsx(
                "mr-2 flex h-12 w-12 items-center justify-center rounded-full duration-300",
                {
                  "bg-blue-600 text-white group-hover:bg-white group-hover:text-blue-700":
                    !isPaymentTabActive,
                  "bg-white text-blue-700": isPaymentTabActive,
                }
              )}
            >
              <AirplaneIcon className="relative -left-0.5 h-5" />
            </div>
            <Typography variant="base">
              <FormattedMessage id="tabBar.link.sendOrRequest" />
            </Typography>
          </NavLink>
        </div>

        <button
          className="mt-auto flex items-center px-10 py-5 duration-300 hover:bg-grey-900 hover:text-blue-700"
          onClick={() => setIsOpenDialog(true)}
        >
          <SignOutIcon className="mr-3.5 h-5" />
          <Typography variant="base">
            <FormattedMessage id="buttons.logout" />
          </Typography>
        </button>

        {isOpenDialog && (
          <Popup isOpen={isOpenDialog} onClose={() => setIsOpenDialog(false)}>
            <ConfirmDialog
              title="logoutDialog.title"
              description="logoutDialog.description"
              isLoading={isLogoutLoading}
              onCancel={() => setIsOpenDialog(false)}
              onSubmit={logout}
            />
          </Popup>
        )}
      </div>
    </div>
  );
};
