import { FormattedMessage } from "react-intl";

const Block3 = () => (
  <div className="bg-white py-10 lg:pb-30">
    <div className="container">
      <h2 className="h2 mb-4 text-center lg:text-[40px]">
        <FormattedMessage id="landing.block3.title" />
      </h2>
      <p className="mx-auto mb-11 text-center text-[18px] text-grey-400 lg:w-7/12">
        <FormattedMessage id="landing.block3.description" />
      </p>
      <ul className="grid gap-8 lg:grid-cols-2">
        <li className="rounded-2xl border border-grey-500 p-6 pb-1.5">
          <img src="/images/landing-block3-1.svg" className="mb-4" alt="profile" />
          <h3 className="mb-4 text-[24px] font-bold lg:text-[32px]">
            <FormattedMessage id="landing.block3.item1.title" />
          </h3>
          <p className="mb-4 text-[18px] text-grey-400">
            <FormattedMessage id="landing.block3.item1.description" />
          </p>
        </li>
        <li className="rounded-2xl border border-grey-500 p-6 pb-1.5">
          <img src="/images/landing-block3-2.svg" className="mb-4" alt="profile" />
          <h3 className="mb-4 text-[24px] font-bold lg:text-[32px]">
            <FormattedMessage id="landing.block3.item2.title" />
          </h3>
          <p className="mb-4 text-[18px] text-grey-400">
            <FormattedMessage id="landing.block3.item2.description" />
          </p>
        </li>
        <li className="rounded-2xl border border-grey-500 p-6 pb-1.5">
          <img src="/images/landing-block3-3.svg" className="mb-4" alt="profile" />
          <h3 className="mb-4 text-[24px] font-bold lg:text-[32px]">
            <FormattedMessage id="landing.block3.item3.title" />
          </h3>
          <p className="mb-4 text-[18px] text-grey-400">
            <FormattedMessage id="landing.block3.item3.description" />
          </p>
        </li>
        <li className="lg rounded-2xl border border-grey-500 p-6 pb-1.5">
          <img src="/images/landing-block3-4.svg" className="mb-4" alt="profile" />
          <h3 className="mb-4 text-[24px] font-bold lg:text-[32px]">
            <FormattedMessage id="landing.block3.item4.title" />
          </h3>
          <p className="mb-4 text-[18px] text-grey-400">
            <FormattedMessage id="landing.block3.item4.description" />
          </p>
        </li>
      </ul>
    </div>
  </div>
);

export default Block3;
