import { IconProps } from "./types";

export function EditIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeDasharray="0 0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M3.788 9.217c-.233.248-.458.735-.503 1.073l-.277 2.43c-.098.877.532 1.477 1.402 1.327l2.415-.412c.338-.06.81-.308 1.043-.563l6.157-6.517c1.065-1.125 1.545-2.408-.112-3.975-1.65-1.553-2.903-1.005-3.968.12L3.788 9.217z" />
        <path d="M8.918 3.788a4.595 4.595 0 0 0 4.087 3.862" />
      </g>
    </svg>
  );
}
