import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import semver from "semver";

import { getVersion } from "api/appApi";
import { Button, Popup } from "components/ui";
import { useSnackBarContext } from "context/SnackbarContext";
import { RefreshIcon } from "icons";

const CURRENT_VERSION = "1.0.2";

const VersionChecker = () => {
  const { openErrorNotification } = useSnackBarContext();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const response = await getVersion();

        if (semver.lt(CURRENT_VERSION, response.data.version)) {
          setIsOpen(true);
        }
      } catch (error) {
        openErrorNotification(error);
      }
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      {isOpen && (
        <Popup isOpen={isOpen} onClose={() => setIsOpen(false)} className="text-center">
          <RefreshIcon className="mx-auto mb-8 h-36 w-36" />
          <p className="mb-1 text-h5">
            <FormattedMessage id="versionDialog.title" />
          </p>
          <p className="text-h3 mb-4 text-grey-100">
            <FormattedMessage id="versionDialog.subtitle" />
          </p>
          <p className="mb-20 mt-10 text-base">
            <FormattedMessage id="versionDialog.description" />
          </p>
          <Button variant="contained" className="mt-auto w-full" onClick={handleReload} autoFocus>
            <FormattedMessage id="versionDialog.reload" />
          </Button>
        </Popup>
      )}
    </>
  );
};

export default VersionChecker;
