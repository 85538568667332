import { IconProps } from "./types";

export function BankIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19" {...props}>
      <path
        fill="currentColor"
        d="M17.75 7.45c.96 0 1.75-.79 1.75-1.75V4.42c0-.75-.48-1.42-1.2-1.66l-8-2.67c-.36-.12-.75-.12-1.11 0L1.2 2.76C.48 3 0 3.67 0 4.42V5.7c0 .96.79 1.75 1.75 1.75H2v6.5h-.25c-.96 0-1.75.79-1.75 1.75v1c0 .96.79 1.75 1.75 1.75h16c.96 0 1.75-.79 1.75-1.75v-1c0-.96-.79-1.75-1.75-1.75h-.25v-6.5h.25zM1.5 5.7V4.42c0-.11.07-.2.17-.24l8-2.67s.05-.01.08-.01.05 0 .08.01l8 2.67c.1.03.17.13.17.24V5.7c0 .14-.11.25-.25.25h-16c-.14 0-.25-.11-.25-.25zm5 8.25v-6.5H13v6.5H6.5zm-3-6.5H5v6.5H3.5v-6.5zM18 15.7v1c0 .14-.11.25-.25.25h-16c-.14 0-.25-.11-.25-.25v-1c0-.14.11-.25.25-.25h16c.14 0 .25.11.25.25zm-2-1.75h-1.5v-6.5H16v6.5z"
      />
    </svg>
  );
}
