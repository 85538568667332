import clsx from "clsx";

import { Typography } from "components/Typography";

export const Stepper = ({
  className,
  steps = [],
  activeStep,
}: {
  className?: string;
  steps: Array<string>;
  activeStep: number;
}) => {
  return (
    <ul className={clsx("flex w-full flex-col justify-center xl:flex-row", className)}>
      {steps.map((step, i) => {
        const index = i + 1;
        const isCompleted = index < activeStep;
        const isActive = index === activeStep;
        const isLast = index === steps.length;

        return (
          <li
            key={step}
            className={clsx("mb-3 flex items-center xl:mb-0", {
              [`flex-1 xl:w-1/5 xl:max-w-sm`]: !isLast, // TODO do not hardcode width
            })}
          >
            <div className="flex shrink-0">
              <Typography
                variant="body"
                className={clsx(
                  "mr-2 flex h-6 w-6 shrink-0 items-center justify-center rounded-full text-white",
                  {
                    "bg-grey-0": !isCompleted && !isActive,
                    "bg-blue-700": isActive || isCompleted,
                  }
                )}
              >
                {isCompleted ? "✓" : index}
              </Typography>

              <Typography
                className={clsx("shrink-0", {
                  "text-grey-0": !isActive && !isCompleted,
                })}
                variant="base"
              >
                {step}
              </Typography>
            </div>
            {!isLast && (
              <span className="mx-4 mt-0.5 hidden h-px w-full bg-grey-200 xl:flex"></span>
            )}
          </li>
        );
      })}
    </ul>
  );
};
