import { Helmet } from "react-helmet";

const CanonicalHelmetLink = () => {
  return (
    <Helmet>
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default CanonicalHelmetLink;
