export const BREADCRUMBS_VALUES: { [key: string]: string } = {
  "": "Enviago",
  "/": "Enviago",
  "/profile": "pages.profile.title",
  "/profile/price-point": "pages.pricePoints.title",
  "/settings": "pages.settings.title",
  "/settings/payment-methods": "pages.paymentMethods.title",
  "/settings/payment-methods/add-new-card": "pages.addNewCard.title",
  "/settings/withdraw-method": "pages.withdrawMethod.title",
  "/settings/legal": "pages.legal.title",
  "/settings/withdraw-method/update-bank-account": "pages.updateBankAccount.title",
  "/withdraw-funds": "pages.withdrawFunds.title",
  "/withdraw-funds/withdrawal-details": "pages.withdrawalDetails.title",
  "/payment": "pages.payment.title",
  "/payment/send": "pages.paymentSend.title",
  "/payment/request": "pages.paymentRequest.title",
  "/transactions": "pages.transactions.title",
  "/transactions/transaction-details": "pages.transactionDetails.title",
  "/change-password": "pages.changePassword.title",
  "/contacts": "pages.contacts.title",
  "/account-info": "pages.accountInfo.title",
};
