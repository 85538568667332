import {
  parseTransaction,
  parseTransactionMessage,
  parseTransactions,
} from "services/transactionsServices";

import instance from "./instance";

export const fetchGetTransaction = ({ userId, paymentId }: { userId: string; paymentId: string }) =>
  instance
    .get(`app/user/${userId}/payment?paymentId=${paymentId}`)
    .then((res) => parseTransaction(res.data[0] || null, userId)); // TODO TEMP

export const fetchGetTransactions = ({
  userId,
  startPage,
  endPage,
  status,
}: {
  userId: string;
  startPage?: number;
  endPage?: number;
  status?: string;
}) =>
  instance
    .get(`app/user/${userId}/payment/list`, {
      params: { startPage, endPage, status },
    })
    .then((res) => parseTransactions(res.data, userId));

export const fetchSendTransactionPayment = ({
  userId,
  ...payload
}: {
  userId: string;
  amount: number;
  message: string;
  paymentMethodId: string;
  receiverUserId: string;
  origPaymentId?: string;
  request?: boolean;
}) =>
  instance.post(`app/user/${userId}/payment/send/`, {
    ...payload,
  });

export const fetchRequestTransactionPayment = ({
  userId,
  amount,
  message,
  receiverUserId,
}: {
  userId: string;
  amount: number;
  message: string;
  receiverUserId: string;
}) =>
  instance.post(`app/user/${userId}/payment/request/`, {
    receiverUserId,
    amount,
    message,
  });

export const fetchCancelTransactionPayment = ({
  userId,
  paymentId,
}: {
  userId: string;
  paymentId: string;
}) =>
  instance.delete(`app/user/${userId}/payment/request/`, {
    data: { paymentId },
  });

export const fetchRemindTransactionPayment = ({
  userId,
  paymentId,
}: {
  userId: string;
  paymentId: string;
}) =>
  instance.post(`app/user/${userId}/payment/request/reminder`, {
    paymentId,
  });

export const fetchSendTransactionMessage = ({
  userId,
  paymentId,
  message,
  messageMediaId,
}: {
  userId: string;
  paymentId: string;
  message: string;
  messageMediaId?: string;
}) =>
  instance
    .post(`app/user/${userId}/messages/`, {
      paymentId,
      message,
      messageMediaId,
    })
    .then((res) => parseTransactionMessage(res.data));
