import clsx from "clsx";

export default function BurgerButton({
  className,
  onClick,
  isMenuOpen,
}: {
  className?: string;
  onClick: () => void;
  isMenuOpen: boolean;
}) {
  return (
    <button
      type="button"
      className={clsx(
        className,
        "bg-primary relative flex h-9 w-9 flex-col items-center justify-center rounded-lg",
        {
          "lg:hidden": !isMenuOpen,
        }
      )}
      aria-controls="mobile-menu"
      aria-expanded="false"
      onClick={onClick}
    >
      <span className="sr-only">Open main menu</span>
      <div
        className={clsx("absolute top-1/2 -mt-px h-0.5 w-4 bg-black transition", {
          "-translate-y-1.5": !isMenuOpen,
          "translate-y-0 rotate-45": isMenuOpen,
        })}
      />
      <div
        className={clsx("absolute top-1/2 -mt-px h-0.5 w-4 bg-black transition", {
          "opacity-0": isMenuOpen,
        })}
      />
      <div
        className={clsx("absolute top-1/2 -mt-px h-0.5 w-4 bg-black transition", {
          "translate-y-1.5": !isMenuOpen,
          "translate-y-0 -rotate-45": isMenuOpen,
        })}
      />
    </button>
  );
}
