import clsx from "clsx";
import { ReactNode } from "react";

import { Typography } from "components/Typography";

interface ChipProps {
  children: ReactNode;
  color: "orange" | "grey" | "green" | "red";
  className?: string;
}

const Chip = ({ children, color, className }: ChipProps) => {
  return (
    <Typography
      className={clsx(
        "mb-1 rounded-2xl px-2 py-1 font-bold capitalize",
        {
          "bg-orange/25 text-orange": color === "orange",
          "bg-red/25 text-red": color === "red",
          "bg-green/25 text-green": color === "green",
          "bg-grey-300/25 text-grey-300": color === "grey",
        },
        className
      )}
      variant="body-lg"
    >
      {children}
    </Typography>
  );
};

export default Chip;
