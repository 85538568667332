import clsx from "clsx";
import React from "react";

import { Typography } from "components/Typography";

interface IDividerProps {
  className?: string;
  children?: React.ReactNode;
  color?: "primary" | "secondary";
}

export const Divider = ({ color = "primary", className, children }: IDividerProps) => (
  <div
    className={clsx(
      "border-sold relative my-3 flex h-0 w-full justify-center border",
      { "border-grey-600": color === "primary" },
      className
    )}
  >
    {children && (
      <div className="absolute -mt-3 bg-white px-4 font-semibold text-grey-300">
        <Typography variant="caption">{children}</Typography>
      </div>
    )}
  </div>
);
