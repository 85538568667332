import { lazy } from "react";

import { AuthLayout } from "components/Layout/AuthLayout";
import { Loadable } from "components/ui";

const SetUp = Loadable(lazy(() => import("pages/SetUp")));

const LoggedInWithoutSetUp = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/set-up",
      element: <SetUp />,
    },
  ],
};

export default LoggedInWithoutSetUp;
