import { IconProps } from "./types";

export function PhoneIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 51" {...props}>
      <path
        d="M12.639.019a7.04 7.04 0 0 0-2.509.27l-2.978.882C3.978 2.11 1.498 4.628.659 7.806c-1.863 7.068.268 15.434 5.814 24.852 5.539 9.404 11.85 15.38 18.976 17.332 3.21.878 6.671.039 9.102-2.22l2.266-2.1c2.535-2.354 2.913-6.275.865-9.06l-3.273-4.446c-1.755-2.383-4.848-3.398-7.692-2.544l-5.12 1.534c-.216.062-.73-.23-1.178-.617l-.002-.003c-.798-.693-1.903-2.094-3.084-4.098v-.003c-1.268-2.152-1.808-3.56-2.025-4.391-.217-.832-.165-.933-.133-1.214a.596.596 0 0 1 .197-.374l.002-.002 3.819-3.492c2.188-2 2.848-5.196 1.614-7.886l-2.302-5.023V4.05C17.42 1.686 15.123.199 12.639.019zm-.273 3.764c1.155.087 2.22.78 2.706 1.84l2.305 5.024c.558 1.216.278 2.607-.73 3.529l-3.819 3.49a4.38 4.38 0 0 0-1.403 2.737v.002c-.031.276-.105 1.304.231 2.59.336 1.287 1.03 2.988 2.423 5.355h.002c1.3 2.205 2.53 3.881 3.861 5.037h.002c.433.375 2.244 2.111 4.709 1.391l.007-.002 5.143-1.54c1.336-.402 2.764.077 3.566 1.166l3.273 4.446a3.035 3.035 0 0 1-.393 4.054l-2.266 2.104c-1.449 1.347-3.6 1.872-5.538 1.342-5.762-1.577-11.464-6.682-16.718-15.605C4.465 21.81 2.819 14.42 4.308 8.77c.493-1.864 2-3.412 3.915-3.978l2.976-.882a3.256 3.256 0 0 1 1.167-.126z"
        fill="#313381"
      />
    </svg>
  );
}
