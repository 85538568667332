import { IconProps } from "./types";

export function CirculationIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M16.75 0h-6C9.23 0 8 1.23 8 2.75v3C8 7.27 9.23 8.5 10.75 8.5h6c1.52 0 2.75-1.23 2.75-2.75v-3C19.5 1.23 18.27 0 16.75 0zm0 7h-6c-.69 0-1.25-.56-1.25-1.25v-3c0-.69.56-1.25 1.25-1.25h6c.69 0 1.25.56 1.25 1.25V3h-5.25c-.41 0-.75.34-.75.75s.34.75.75.75H18v1.25C18 6.44 17.44 7 16.75 7zm-8 4h-6C1.23 11 0 12.23 0 13.75v3c0 1.52 1.23 2.75 2.75 2.75h6c1.52 0 2.75-1.23 2.75-2.75v-3c0-1.52-1.23-2.75-2.75-2.75zm-6 1.5h6c.69 0 1.25.56 1.25 1.25V14H1.5v-.25c0-.69.56-1.25 1.25-1.25zm6 5.5h-6c-.69 0-1.25-.56-1.25-1.25V15.5H10v1.25c0 .69-.56 1.25-1.25 1.25zm10-8c-.41 0-.75.34-.75.75v3c0 .14-.11.25-.25.25h-2.19l.72-.72c.29-.29.29-.77 0-1.06a.754.754 0 0 0-1.06 0l-2 2c-.07.07-.12.15-.16.24-.08.18-.08.39 0 .57.04.09.09.17.16.24l2 2c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06l-.72-.72h2.19c.96 0 1.75-.79 1.75-1.75v-3c0-.41-.34-.75-.75-.75V10zm-18-.5c.41 0 .75-.34.75-.75v-3c0-.14.11-.25.25-.25h2.19l-.72.72c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l2-2c.07-.07.12-.15.16-.24.08-.18.08-.39 0-.57a.776.776 0 0 0-.16-.24l-2-2a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06l.72.72H1.75C.79 4.01 0 4.8 0 5.76v3c0 .41.34.75.75.75V9.5z"
        fill="currentColor"
      />
    </svg>
  );
}
