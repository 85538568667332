import clsx from "clsx";
import { formatDistance, parseISO } from "date-fns";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import CardContainer from "components/CardContainer";
import { TransactionAmount } from "components/TransactionAmount";
import { Typography } from "components/Typography";
import { useTheme } from "context/ThemeContext";
import { DepositIcon } from "icons/DepositIcon";
import { DATE_FNS_LOCALE } from "locales";
import { ITransaction } from "types/transaction";

export interface IDepositTransactionCardProps {
  className?: string;
  data: ITransaction;
}

export const DepositTransactionCard: FC<IDepositTransactionCardProps> = ({ className, data }) => {
  const { locale } = useTheme();
  const navigate = useNavigate();

  const { createdAt } = data;

  const handleNavigateToDetails = () => {
    navigate(`/withdraw-funds/deposit-details?id=${createdAt}`);
  };

  return (
    <CardContainer
      className={clsx(className, "flex-col !items-end")}
      onClick={handleNavigateToDetails}
    >
      <div className="flex h-full w-full items-center">
        <div className="mr-3.5 p-0.5">
          <DepositIcon className="h-9 w-9 text-blue-700" />
        </div>
        <div className="overflow-hidden">
          <Typography className="truncate font-semibold text-blue-0" variant="body-lg">
            Deposit
          </Typography>
          <Typography className="text-grey-0" variant="caption">
            {formatDistance(parseISO(createdAt), new Date(), {
              addSuffix: true,
              locale: DATE_FNS_LOCALE[locale],
            })}
          </Typography>
        </div>
        <div className="ml-auto flex flex-col items-end">
          <TransactionAmount
            className="text-blue-700"
            amount={data.amount}
            variant="h6"
            amountPrefix="+"
          />
        </div>
      </div>
    </CardContainer>
  );
};
