import { IconProps } from "./types";

export function HomeIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" {...props}>
      <path
        d="m15.91 4.777-4-3.57a4.739 4.739 0 0 0-6.32 0l-4 3.57A4.755 4.755 0 0 0 0 8.328v6.36c0 2.1 1.68 3.81 3.75 3.81h10c2.07 0 3.75-1.71 3.75-3.81v-6.36c0-1.35-.58-2.65-1.59-3.55zM11 16.988H6.5v-3.25c0-1.24 1.01-2.25 2.25-2.25s2.25 1.01 2.25 2.25v3.25zm5-2.31c0 1.27-1.01 2.31-2.25 2.31H12.5v-3.25c0-2.07-1.68-3.75-3.75-3.75-2.07 0-3.75 1.68-3.75 3.75v3.25H3.75c-1.24 0-2.25-1.03-2.25-2.31v-6.36c0-.93.4-1.81 1.09-2.43l4-3.57a3.24 3.24 0 0 1 4.32 0l4 3.57c.69.62 1.09 1.5 1.09 2.43v6.36z"
        fill="currentColor"
      />
    </svg>
  );
}
