import { IconProps } from "./types";

export function NotifyIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 139 144"
      {...props}
    >
      <defs>
        <path
          id="b"
          d="M66 134c29.823 0 54-24.177 54-54S95.823 26 66 26 12 50.177 12 80s24.177 54 54 54z"
        />
        <path
          id="c"
          d="M66 138c32.033 0 58-25.967 58-58S98.033 22 66 22 8 47.967 8 80s25.967 58 58 58z"
        />
        <mask
          id="e"
          width="100"
          height="100"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#a" />
        </mask>
        <mask
          id="f"
          width="108"
          height="108"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#b" />
        </mask>
        <mask
          id="g"
          width="116"
          height="116"
          x="0"
          y="0"
          fill="#fff"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
        >
          <use xlinkHref="#c" />
        </mask>
        <radialGradient
          id="d"
          cx="39.741%"
          cy="70.238%"
          r="101.892%"
          fx="39.741%"
          fy="70.238%"
          gradientTransform="matrix(.76674 0 0 1 .093 0)"
        >
          <stop offset="0%" stopColor="#313381" />
          <stop offset="20%" stopColor="#313381" />
          <stop offset="58%" stopColor="#313381" />
          <stop offset="100%" stopColor="#313381" />
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            fill="#313381"
            d="M92.112 59.635a5.039 5.039 0 0 0-3.804-.45 5.022 5.022 0 0 0-1.742.877S59.288 82.748 58.5 83.432l-10.74-9.358a6.315 6.315 0 0 0-3.959-1.549 6.323 6.323 0 0 0-4.047 1.306c-2.733 2.106-2.835 5.237-.707 7.943l13.38 18.287a7.583 7.583 0 0 0 5.353 2.86 7.608 7.608 0 0 0 5.503-1.754L93.23 66.684l.09-.097c1.663-1.77 1.831-3.746.277-5.694a4.989 4.989 0 0 0-1.484-1.258z"
          />
          <path
            fill="url(#d)"
            d="M54.739.635a5.039 5.039 0 0 0-3.805-.45 5.022 5.022 0 0 0-1.741.877s-27.278 22.686-28.067 23.37l-10.74-9.358a6.315 6.315 0 0 0-4.234-1.517 6.31 6.31 0 0 0-4.171 1.681 6.167 6.167 0 0 0-.698 8.293l13.77 17.514a7.583 7.583 0 0 0 5.353 2.86 7.608 7.608 0 0 0 5.504-1.754L55.855 8.44l.09-.097a4.922 4.922 0 0 0 .277-6.451A4.989 4.989 0 0 0 54.74.635z"
            transform="translate(37.374 57.067)"
          />
        </g>
        <use
          xlinkHref="#b"
          stroke="#2961FF"
          strokeDasharray="0 0"
          strokeOpacity=".523"
          strokeWidth="1.6"
          mask="url(#f)"
        />
        <use
          xlinkHref="#c"
          stroke="#2961FF"
          strokeDasharray="0 0"
          strokeOpacity=".08"
          strokeWidth="2"
          mask="url(#g)"
        />
        <path
          fill="#313381"
          d="M12 32a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm-7 96a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm98-106a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm32 36a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM18 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm99 37a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-14 100a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-81 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM4 50a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        />
      </g>
    </svg>
  );
}
