export const TRANSACTION_FEE = 0.05;
export const ENVIAGO_WALLET_PAYMENT_ID = "enviago-wallet";

export enum TRANSACTION_STATUS {
  AWAITING = "awaiting",
  COMPLETED = "complete",
  NEW = "new",
  REQUESTED = "requested",
}

export enum WITHDRAWAL_TRANSACTION_STATUS {
  PROCESSING = "processing",
  CANCELLED = "cancelled",
  REJECTED = "rejected",
  COMPLETE = "complete",
  CREATED = "created",
  FAILED = "failed",
  UNKNOWN = "unknown",
}
