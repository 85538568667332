import axios from "axios";
import Cookies from "js-cookie";

import { STORAGE_PREFIX } from "configs/app.config";

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
};

const instance = axios.create(defaultOptions);
export const unauthorizedApiInstance = axios.create(defaultOptions);

(function () {
  const cookiesUser = Cookies.get(`${STORAGE_PREFIX}user`);
  let user;

  if (cookiesUser) {
    user = JSON.parse(cookiesUser);
  }

  instance.defaults.headers.common.authtoken = user?.authToken;
})();

export default instance;
