import { FormattedMessage } from "react-intl";

const Block2 = () => (
  <div className="bg-white pt-10 lg:pb-30 lg:pt-30">
    <div className="container relative lg:flex">
      <div className="lg:w-1/2">
        <h2 className="h2 mb-6 lg:mt-10 lg:text-[40px]">
          <FormattedMessage id="landing.block2.title" />
        </h2>
        <ul className="w-10/12">
          <li className="mb-6 ">
            <h3 className="h6 mb-4 font-bold">
              <FormattedMessage id="landing.block2.item1.title" />
            </h3>
            <p className="text-grey-400">
              <FormattedMessage id="landing.block2.item1.description" />
            </p>
          </li>
          <li className="mb-6">
            <h3 className="h6 mb-4 font-bold">
              <FormattedMessage id="landing.block2.item2.title" />
            </h3>
            <p className="text-grey-400">
              <FormattedMessage id="landing.block2.item2.description" />
            </p>
          </li>
          <li>
            <h3 className="h6 mb-4 font-bold">
              <FormattedMessage id="landing.block2.item3.title" />
            </h3>
            <p className="text-grey-400">
              <FormattedMessage id="landing.block2.item3.description" />
            </p>
          </li>
        </ul>
      </div>
      <img
        className="mt-8 lg:absolute lg:-right-12 lg:-top-8 lg:max-w-[780px]"
        src="images/landing-block2.png"
        alt="showcases"
      />
    </div>
  </div>
);

export default Block2;
