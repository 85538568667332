import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { IntlProvider } from "react-intl";

import { SnackBar } from "components/ui";
import VersionChecker from "components/VersionChecker";
import { useTheme } from "context/ThemeContext";
import { useInterceptor } from "hooks/useInterceptor";
import useMouseFlow from "hooks/useMouseFlow";
import { AppLocale } from "locales";
import Routes from "routes";

const App = () => {
  const { locale } = useTheme();
  const currentAppLocale = AppLocale[locale];

  useInterceptor();

  useMouseFlow();

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <VersionChecker />
      <ReactQueryDevtools initialIsOpen={false} />
      <SnackBar />
      <Routes />
    </IntlProvider>
  );
};

export default App;
