import { IconProps } from "./types";

export function CameraIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M19.599 6h-2.323l-.714-2.015C16.34 3.319 15.719 3 15.017 3H8.983c-.702 0-1.323.319-1.545.985L6.724 6H4.401C2.905 6 2 7.247 2 8.743v9.77C2 20.009 2.905 21 4.401 21h15.197C21.095 21 22 20.009 22 18.513v-9.77C22 7.247 21.095 6 19.599 6zM21 18.513c0 .898-.503 1.487-1.401 1.487H4.401C3.503 20 3 19.411 3 18.513v-9.77C3 7.846 3.503 7 4.401 7h2.714c.234 0 .441-.092.515-.313l.838-2.415C8.542 4.05 8.749 4 8.983 4h6.034c.234 0 .441.078.515.301l.838 2.421c.074.221.281.278.515.278h2.714C20.497 7 21 7.846 21 8.743v9.77z" />
      <path d="M12 7.658c-2.993 0-5.428 2.435-5.428 5.428S9.007 18.513 12 18.513s5.428-2.435 5.428-5.428S14.993 7.658 12 7.658zm0 9.77c-2.394 0-4.342-1.948-4.342-4.342S9.606 8.743 12 8.743s4.342 1.948 4.342 4.342-1.948 4.343-4.342 4.343z" />
    </svg>
  );
}
