import { IconProps } from "./types";

export function ShareIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 31" {...props}>
      <path
        fill="currentColor"
        d="M9.294.13C4.417.13.447 4.099.447 8.976v12.307c0 4.877 3.97 8.847 8.847 8.847a8.771 8.771 0 0 0 5.307-1.77 1.154 1.154 0 0 0-1.384-1.846 6.436 6.436 0 0 1-3.923 1.308c-3.6 0-6.539-2.939-6.539-6.539V8.976c0-3.6 2.939-6.539 6.539-6.539 3.6 0 6.538 2.939 6.538 6.539v8.461a2.705 2.705 0 0 1-2.692 2.693 2.705 2.705 0 0 1-2.693-2.693v-6.923c0-.63-.523-1.154-1.153-1.154-.631 0-1.154.523-1.154 1.154v6.923c0 2.754 2.246 5 5 5s5-2.246 5-5V8.976C18.14 4.099 14.17.13 9.294.13z"
      />
    </svg>
  );
}
