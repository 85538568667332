import { IconProps } from "./types";

export function ProfileIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21" {...props}>
      <path
        fill="currentColor"
        d="M8.25 10.5a4.26 4.26 0 0 0 4.25-4.25A4.26 4.26 0 0 0 8.25 2 4.26 4.26 0 0 0 4 6.25a4.26 4.26 0 0 0 4.25 4.25zm0-7C9.77 3.5 11 4.73 11 6.25S9.77 9 8.25 9 5.5 7.77 5.5 6.25 6.73 3.5 8.25 3.5zm6.42 10.31C13.33 12.99 11.13 12 8.25 12s-5.08.98-6.43 1.81c-.52.32-.82.86-.82 1.46v3.49c0 .96.82 1.75 1.83 1.75h10.83c1.01 0 1.83-.79 1.83-1.75v-3.48c0-.6-.31-1.14-.83-1.46l.01-.01zM14 18.75c0 .14-.15.25-.33.25H2.83c-.18 0-.33-.11-.33-.25v-3.49c0-.07.04-.14.11-.18 1.18-.72 3.11-1.58 5.64-1.58s4.46.86 5.64 1.58c.07.04.11.11.11.19v3.48zM15.75 7c-.41 0-.75.34-.75.75v1c0 .69-.56 1.25-1.25 1.25h-1c-.41 0-.75.34-.75.75s.34.75.75.75h1c1.52 0 2.75-1.23 2.75-2.75v-1c0-.41-.34-.75-.75-.75zm-2-7h-1c-.41 0-.75.34-.75.75s.34.75.75.75h1c.69 0 1.25.56 1.25 1.25v1c0 .41.34.75.75.75s.75-.34.75-.75v-1C16.5 1.23 15.27 0 13.75 0zm-13 4.5c.41 0 .75-.34.75-.75v-1c0-.69.56-1.25 1.25-1.25h1c.41 0 .75-.34.75-.75S4.16 0 3.75 0h-1C1.23 0 0 1.23 0 2.75v1c0 .41.34.75.75.75zm3 7c.41 0 .75-.34.75-.75S4.16 10 3.75 10h-1c-.69 0-1.25-.56-1.25-1.25v-1c0-.41-.34-.75-.75-.75S0 7.34 0 7.75v1c0 1.52 1.23 2.75 2.75 2.75h1z"
      />
    </svg>
  );
}
