import clsx from "clsx";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Typography } from "components/Typography";
import useCopyLink from "hooks/useCopyLink";

interface ICopyLinkProps {
  className?: string;
  username: string;
}

export const CopyLink: FC<ICopyLinkProps> = ({ className, username }) => {
  const { handleCopy } = useCopyLink(username);

  return (
    <>
      <button
        className={clsx(
          "flex w-full flex-col rounded-2xl bg-blue-700/20 px-4 py-3 text-blue-600",
          className
        )}
        onClick={handleCopy}
        type="button"
      >
        <div className="mb-1.5 flex w-full overflow-hidden">
          <Typography className="mr-1 shrink-0" variant="caption">
            <FormattedMessage id="buttons.shareLink.preLink" />
          </Typography>
          <Typography className="truncate text-blue-700/90 underline" variant="caption">
            {process.env.REACT_APP_DOMAIN}/<span className="font-bold">{username}</span>
          </Typography>
        </div>
        <div className="flex w-full items-center">
          <Typography className="mr-1 text-left font-bold">
            <FormattedMessage id="buttons.shareLink.description" />
          </Typography>
          <Typography className="ml-auto shrink-0 rounded-2xl bg-white px-2 py-1 font-bold text-blue-600">
            <FormattedMessage id="buttons.shareLink.copy" />
          </Typography>
        </div>
      </button>
    </>
  );
};
