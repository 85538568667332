import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "components/ui";
import { ButtonVariant } from "components/ui/Button";
import { CrossIcon, VideoCameraIcon } from "icons";

function EnviagoYoutubeVideoButton({
  variant,
  className,
}: {
  variant?: ButtonVariant;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const closePopup = (e: React.MouseEvent<HTMLDivElement | SVGElement>) => {
    if ((e.target as Element).classList.contains("popup")) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button variant={variant} className={className} onClick={() => setIsOpen(true)}>
        <VideoCameraIcon className="mr-2 h-5 w-5" />
        <FormattedMessage id="landing.block1.button2" />
      </Button>
      {isOpen && (
        <div
          className="popup fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closePopup}
        >
          <div className="popup-content relative mx-auto flex min-h-[50%] min-w-[80%] max-w-2xl rounded-lg bg-white p-1 shadow-lg xl:min-w-[50%]">
            <CrossIcon
              className="absolute -right-[25px] top-0 h-[20px] w-[20px] cursor-pointer text-white"
              onClick={() => setIsOpen(false)}
            />
            <iframe
              width="100%"
              src="https://www.youtube.com/embed/ZM91I0hLLkM?autoplay=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </>
  );
}

export default EnviagoYoutubeVideoButton;
