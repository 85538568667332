import { IconProps } from "./types";

export function LetterIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39" {...props}>
      <path
        d="m4.54 13.332 13.683 12.222c.739.66 1.854.66 2.593 0l13.668-12.209-13.917-9.118a1.946 1.946 0 0 0-2.134 0L4.54 13.332zm30.56.418v4.28L23.412 28.473a5.84 5.84 0 0 1-7.785 0L3.9 17.996v-4.244 21.339h31.2V13.75zM1.767 10.478 16.3.955a5.838 5.838 0 0 1 6.4 0l14.533 9.523A3.911 3.911 0 0 1 39 13.75v21.34A3.905 3.905 0 0 1 35.1 39H3.9C1.746 39 0 37.25 0 35.09V13.75c0-1.32.665-2.55 1.767-3.272z"
        fill="currentColor"
      />
    </svg>
  );
}
