import { FC } from "react";

import { DepositTransactionCard } from "components/DepositTransactionCard";
import { UserTransactionCard } from "components/UserTransactionCard";
import { WithdrawalTransactionCard } from "components/WithdrawalTransactionCard";
import { ACTIVITY_TYPE, ITransaction } from "types/transaction";

interface ITransactionCard {
  transaction: ITransaction;
  className?: string;
  isSmallSize?: boolean;
}

const TransactionCard: FC<ITransactionCard> = ({ transaction, className, isSmallSize }) => {
  return (
    <>
      {transaction.type === ACTIVITY_TYPE.WITHDRAWAL && (
        <WithdrawalTransactionCard className={className} data={transaction} />
      )}
      {(transaction.type === ACTIVITY_TYPE.REQUEST || transaction.type === ACTIVITY_TYPE.SEND) && (
        <UserTransactionCard className={className} data={transaction} isSmallSize={isSmallSize} />
      )}
      {transaction.type === ACTIVITY_TYPE.DEPOSIT && (
        <DepositTransactionCard className={className} data={transaction} />
      )}
    </>
  );
};

export default TransactionCard;
