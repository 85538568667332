/* eslint @typescript-eslint/no-explicit-any: 0 */
// Disabling all data any type

import { TRANSACTION_STATUS } from "constants/transaction";
import { ITransaction, ITransactions, ITransactionMessage } from "types/transaction";

export const parseTransactionMessage = (message: any): ITransactionMessage => ({
  createdAt: message.created_at,
  message: message.message,
  messageReceiverUserId: message.message_receiver_user_id,
  messageSenderUserId: message.message_sender_user_id,
  messageId: message?.message_id,
  messageMediaId: message?.message_media_id,
  paymentTriggered: message?.payment_triggered,
});

export const parseTransaction = (transaction: any, userId: string): ITransaction => {
  const paymentStatus = transaction.payment_status?.toLowerCase();

  const isActionRequired =
    paymentStatus === TRANSACTION_STATUS.NEW && transaction.receiver_user_id === userId;

  return {
    amount: transaction.amount,
    signedAmount: transaction.signed_amount,
    createdAt: transaction.created_at,
    paymentId: transaction.payment_id,
    paymentMethodId: transaction.payment_method_id,
    messages: transaction.messages?.map((message: any) => parseTransactionMessage(message)) || [],
    paymentStatus: paymentStatus,
    status: transaction.status?.toLowerCase(),
    transactionType: transaction.transaction_type,
    type: transaction.type,
    receiverPhoto: transaction.receiver_photo,
    receiverUserId: transaction.receiver_user_id,
    receiverUsername: transaction.receiver_username,
    senderPhoto: transaction.sender_photo,
    senderUserId: transaction.sender_user_id,
    senderUsername: transaction.sender_username,
    mediaType: transaction.media_type,
    description: transaction.description,
    isActionRequired,
  };
};

export const parseTransactions = (data: any, userId: string): ITransactions => ({
  totalPages: data.totalPages,
  transactions: data.results.map((transaction: any) => parseTransaction(transaction, userId)),
});
