import { IconProps } from "./types";

export function PasswordShowIcon(props: IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.693 1.55a.794.794 0 0 0-1.093.297l-2.447 4.297c-1.667-.78-3.392-1.18-5.139-1.18-4.693 0-9.233 2.882-12.894 8.3l-.015.021-.012.019a.46.46 0 0 0 0 .552c2.7 4.013 5.884 6.641 9.255 7.746L8.4 25.022a.817.817 0 0 0 .293 1.108l.347.203a.794.794 0 0 0 1.092-.297L23.332 2.86a.817.817 0 0 0-.293-1.108l-.346-.203Zm-4.601 6.457c-1.357-.597-2.727-.888-4.078-.888-3.41 0-6.94 1.854-10.075 5.805-.3.38-.3.932 0 1.311 2.35 2.962 4.922 4.746 7.499 5.454l1.348-2.366c-1.54-.49-2.813-1.86-2.813-3.741 0-2.38 1.824-4.308 4.073-4.308 1.038 0 1.986.41 2.705 1.087l1.341-2.354Zm-2.453 4.307c-.346-.537-.916-.886-1.593-.886-1.125 0-2.046.963-2.046 2.152 0 .786.843 1.705 1.902 1.782l1.737-3.048Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M14.687 22.176c4.444-.261 8.719-3.107 12.2-8.245A.605.605 0 0 0 27 13.58a.571.571 0 0 0-.104-.335c-1.338-1.977-2.794-3.616-4.33-4.9l-1.06 1.86c.883.76 1.747 1.665 2.583 2.719.301.38.301.932 0 1.311-2.521 3.178-5.299 5-8.064 5.592l-1.338 2.35Z"
      />
    </svg>
  );
}
