import clsx from "clsx";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Typography } from "components/Typography";
import { IconType } from "icons/types";

interface ICardButtonProps {
  className?: string;
  icon: IconType;
  title: string;
  onClick: () => void;
}

export const CardButton: FC<ICardButtonProps> = ({ className, icon: Icon, title, onClick }) => (
  <button
    className={clsx(
      "flex flex-1 flex-col items-center justify-center rounded-xl bg-white p-2 lg:rounded-2xl lg:py-4",
      className
    )}
    onClick={onClick}
  >
    <Icon className="mb-0.5 h-5 w-5 text-blue-600 lg:mb-2 lg:h-7 lg:w-7" />
    <Typography className="font-medium text-grey-0" variant="body-lg">
      <FormattedMessage id={title} />
    </Typography>
  </button>
);
